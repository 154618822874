.text-center {
  text-align: center;
}

.flex{
    display: flex;
}
.justify-center{
    justify-content: center;
}
.justify-end{
    justify-content: end !important;
}
.items-center{
    align-items: center;
}
.mb-5{
    margin-bottom: 50px;
}
.mt-5{
    margin-top: 50px !important;
}
.mr-2{
    margin-right: 20px !important;
}
.flex-grow{
    flex-grow: 1 !important;
}
.min-width-unset{
    min-width: unset !important;
}

.guest-standby-list-page{
    width: 70%;
    margin: auto;
}