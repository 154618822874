.table-spinner .ui.inverted.dimmer {
  background-color: transparent !important;
}
.table-spinner .ui.inverted.dimmer .ui.loader:after {
  border-color: #2259a1 transparent transparent !important;
}

.ui.popup {
  padding: 0.5em 0.5em !important;
}

.general-grid-layout {
  margin: 0 !important;
}
.general-grid-layout > .row {
  padding-top: 0 !important;
}

.Toastify__toast {
  padding: 16px !important;
  margin: 0 !important;
  align-items: center;
  min-height: 45px !important;
  height: max-content !important;
  font-family: 'Montserrat' !important;
  font-size: 13px !important;
  border-radius: 5px !important;
  -webkit-border-radius: 5px !important;
  -moz-border-radius: 5px !important;
  -ms-border-radius: 5px !important;
  -o-border-radius: 5px !important;
}

.Toastify__toast-container {
  min-width: 200px;
  max-width: 400px;
  width: max-content !important;
}

.Toastify__toast-body {
  min-height: 13px !important;
  height: auto !important;
  margin: 0 !important;
  margin-right: 24px !important;
  display: flex;
  align-items: center;
}

.Toastify__close-button {
  font-size: 20px !important;
}

.Toastify__toast--error {
  background: #e10000 !important;
}

.Toastify__toast--success {
  background: #45a135 !important;
}

.Toastify__toast--warning {
  background: #e1a100 !important;
}

.iconic-button-custom {
  width: 22px !important;
  height: 22px !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  padding: 0 !important;
  border-radius: 5px !important;
  -webkit-border-radius: 5px !important;
  -moz-border-radius: 5px !important;
  -ms-border-radius: 5px !important;
  -o-border-radius: 5px !important;
  background: none !important;
  margin: 0 !important;
}

.iconic-button-custom:hover {
  background: #d8e1ec !important;
}
.iconic-button-custom:active {
  background: #c2d2e5 !important;
}

textarea:disabled {
  background: #e4e8f0 !important;
}

.standby-twilio-modal {
  width: 600px !important;
  height: auto !important;
}

.checkboxes-list-select {
  background: white;
  display: flex;
  flex-direction: column;
  padding: 3px 8px;
  justify-content: space-between;
  max-height: 200px;
  width: 385px;
  overflow: auto;
}

.checkboxes-list-select > div {
  margin-top: 5px;
  margin-bottom: 5px;
}

.no-results-container {
  width: 100% !important;
  height: 100% !important;
  min-height: 100px;
  background: #e4e8f0;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  color: #8497b0;
  font-size: 13px;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}

.twilio-numbers-container .no-results-container {
  height: 300px !important;
}

.buy-twilio-modal .no-results-container {
  height: calc(100% - 40px) !important;
}

.questions-list-container .no-results-container {
  height: calc(100vh - 284px) !important;
}

.admin-list-table .no-results-container {
  height: calc(100vh - 213px) !important;
}

.dashboard-block .no-results-container {
  height: calc(100% - 44px) !important;
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important;
}

.standby-twilio-content .no-results-container {
  height: 194px !important;
}

.questionnarie-list-container .no-results-container {
  height: 32px !important;
  border-radius: 5px !important;
  -webkit-border-radius: 5px !important;
  -moz-border-radius: 5px !important;
  -ms-border-radius: 5px !important;
  -o-border-radius: 5px !important;
}

.adjuster-standby-list .no-results-container {
  height: calc(100vh - 188px) !important;
}

.finances-adjuster-page-responsive-container {
  flex-grow: 1;
}

.finances-adjuster-page-responsive-container .no-results-container {
  height: 100% !important;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
}

input[type='number'] {
  -moz-appearance: textfield !important;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  display: none !important;
  -webkit-appearance: none !important;
  margin: 0 !important;
}

.dateinput-container {
  display: inline-block;
  position: relative;
  flex: 1;
  max-width: 140px;
}

.dateinput-container .field {
  margin-bottom: 0 !important;
}

.dateinput-container > .icon {
  position: absolute;
  right: 4px;
  top: 4px;
}

.dateinput-container > .icon > button.ui.button {
  background-color: transparent;
  padding: 4px;
  border-radius: 4px;
  margin: 0;
}
.dateinput-container > .icon > button.ui.button:hover {
  background-color: #d8e1ec;
}
.dateinput-container > .icon > button.ui.button:active {
  background-color: #c2d2e5;
}

.dateinput-container .dateinput {
  padding: 0px 0px 0px 0px !important;
}

.dateinput-container .dateinput .ui.input {
  display: flex;
  flex-direction: row;
}

.ui.search.dropdown i {
  left: auto !important;
}

.ui.search.dropdown > .text {
  width: 90%;
  left: 4px !important;
  max-width: 90% !important;
  max-height: 100% !important;
  height: 100%;
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
}

.ui.dropdown .menu {
  min-width: 100% !important;
  width: 100% !important;
  max-width: 100% !important;
}

.clear-date-icon {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  right: 25px !important;
}

.clear-date-icon img {
  height: 12px;
}

.checkbox-group .ui.checkbox {
  min-height: auto !important;
}

.ui.dropdown > .dropdown.icon {
  width: auto !important;
  position: absolute !important;
  right: 5px !important;
  top: 10px;
  height: auto !important;
}

.registration-type-dropdown {
  display: flex !important;
  align-items: center;
  min-width: 60%;
  width: 100%;
}

.registration-type-group {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  height: 100%;
  margin-left: 0 !important;
}

.registration-type-label {
  display: flex;
}

.adjuster-profile-page .registration-type-label {
  padding-top: 5px;
}

.register-grid .registration-type-label {
  padding-top: 7px;
}

.registration-type-label img {
  margin-left: 1rem;
  height: max-content;
}
.registration-type-label img:hover {
  cursor: pointer !important;
}

@media (max-width: 768px) {
  .registration-type-group {
    margin-top: 0.8rem !important;
    margin-bottom: 0.5rem !important;
  }

  .registration-type-label img {
    margin-bottom: 0.5rem;
  }

  .adjuster-profile-page .registration-type-label img {
    margin-bottom: 0rem;
  }
  .adjuster-profile-page .registration-type-label {
    padding-top: 0rem;
  }

  .register-grid .registration-type-label {
    padding-top: 0px;
  }
}

.registration-type-group > .field:first-child {
  padding-left: 0 !important;
}

.registration-type-group .ui.checkbox input[type='radio'] {
  outline: none !important;
  border: none !important;
}

.registration-type-group .ui.checkbox input[type='radio'] ~ label {
  outline: none !important;
  border: none !important;
}

.registration-type-group .ui.checkbox input[type='radio'] ~ label::before {
  background: center / contain no-repeat url('../images/Radio/radio-0-norm.svg') !important;
  outline: none !important;
  border: none !important;
}

.registration-type-group .ui.checkbox input[type='radio'] ~ label:hover::before {
  background: center / contain no-repeat url('../images/Radio/radio-0-hover.svg') !important;
}

.registration-type-group .ui.checkbox input[type='radio']:checked ~ label:before {
  background: center / contain no-repeat url('../images/Radio/radio-1-norm.svg') !important;
  opacity: 1;
  outline: none !important;
  border: none !important;
}

.registration-type-group .ui.checkbox input[type='radio']:checked ~ label:hover:before {
  background: center / contain no-repeat url('../images/Radio/radio-1-hover.svg') !important;
}

.registration-type-group .ui.checkbox input[type='radio'] ~ label:active:before {
  background: center / contain no-repeat url('../images/Radio/radio-10-pressed.svg') !important;
}

.registration-type-group .ui.checkbox input[type='radio']:checked ~ label:after {
  background: transparent !important;
  outline: none !important;
  border: none !important;
}

.registration-type-group .error-label-custom {
  width: 100%;
}

.avatar-scaled-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.avatar-scaled-container button {
  margin-top: 1rem !important;
}

.avatar-scaled-container img {
  max-height: 280px;
  max-width: 380px;
  object-fit: cover;
}

.read-only-phone-input {
  position: relative;
}

.read-only-phone-input .form-input {
  background: #e4e8f0 !important;
}

.read-only-phone-input .change-input-label {
  position: absolute;
  font-weight: bold;
  font-size: 13px;
  top: 7px;
  right: 10px !important;
}

.read-only-phone-input > div {
  max-width: 65% !important;
}

.phone-field-custom-input > div:last-child {
  width: 65% !important;
  min-width: 65% !important;
}
.register-grid .phone-field-custom-input > div:last-child {
  width: 100% !important;
  min-width: 65% !important;
}

@media (max-width: 768px) {
  .read-only-phone-input .change-input-label {
    right: 10px !important;
  }

  .read-only-phone-input > div {
    max-width: 100% !important;
  }

  .phone-field-custom-input > div:last-child {
    width: 100% !important;
    min-width: 65% !important;
  }
}
@media (min-width: 768px) {
  .read-only-phone-input .change-input-label {
    right: 10px !important;
  }
}

.phone-input-custom-container .form-input {
  font-size: 13px !important;
  padding-left: 8px !important;
}

.form-input-short .error-label-custom {
  padding-top: 6px !important;
}

.ui.dropdown > .dropdown.icon {
  z-index: 10 !important;
}

.ui.form .fields.error .field .ui.dropdown,
.ui.form .field.error .ui.dropdown {
  border-color: none !important;
}

.ui.dropdown.error > .menu,
.ui.dropdown.error > .menu .menu {
  border-color: rgba(216, 220, 230) !important;
}
