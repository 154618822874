.sbl-form-container {
  margin-bottom: 5px;
  min-height: 120px;
  height: auto;
  width: 100% !important;
  display: flex;
  align-items: flex-start;
  background: #dadfe9;
  padding: 10px;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  position: relative;
}

.sbl-form-container textarea {
  font-size: 13px !important;
  padding: 7px 8px !important;
  resize: none !important;
}

.sbl-form-container .dropdown.form-input-dropdown .menu.visible {
  max-width: 100% !important;
  min-width: 0 !important;
  overflow-x: auto !important;
}

.sbl-form-container .ui.grid > .row > [class*='ten wide'].column {
  width: 100% !important;
}

.sbl-form-page {
  max-width: 400px;
}

.standby-dropdown {
  width: 100%;
  background: white;
  height: 100% !important;
  display: flex !important;
  align-items: center !important;
  padding: 0 5px !important;
  border-radius: 5px !important;
  -webkit-border-radius: 5px !important;
  -moz-border-radius: 5px !important;
  -ms-border-radius: 5px !important;
  -o-border-radius: 5px !important;
}

.standby-dropdown .text,
.standby-dropdown input.search {
  width: 100%;
  height: 100%;
  line-height: 32px;
}

.create-sbl-page .field-input-container {
  width: 75% !important;
  min-height: 32px !important;
}

.create-sbl-page .form-input-short .dateinput-container .dateinput {
  margin-bottom: 0 !important;
}

.create-sbl-page .form-input-short .dateinput-container .ui.input {
  width: 145px !important;
  min-width: 145px !important;
}

.create-sbl-page .form-input-short .dateinput-container > .icon {
  left: 110px !important;
  width: 20px !important;
}

.create-sbl-page .form-input-short {
  flex-direction: column;
  min-width: 0 !important;
}

.create-sbl-page .zip-form-input {
  min-width: 75% !important;
}

.create-sbl-page .zip-form-input .field.form-input-short.form-input-dropdown {
  height: auto !important;
  min-height: 32px !important;
}

.create-sbl-page .zip-form-input .dropdown.form-input-short.form-input-dropdown {
  width: 98% !important;
}

.create-sbl-page .zip-form-input .error-label-custom {
  white-space: nowrap;
}

.create-sbl-page .standby-predefined .field-input-container {
  width: calc(75% - 15px) !important;
}

.save-sbl-button button {
  width: 120px;
  height: 32px;
}

.sbl-item-container {
  box-shadow: 0 0 0 0 !important;
  border: none !important;
  padding: 12px !important;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0.5rem 0 !important;
}

.sbl-item-container > div {
  display: flex;
  align-items: center;
}

.sbl-item-title {
  margin-left: 12px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  /* max-width: 400px; */
}

.sbl-item-title > div {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 100%;
}

.sbl-item-dates-section {
  color: #8497b0;
  font-size: 13px;
  text-align: right;
  max-width: 235px;
}

.details-header-container .sbl-item-dates-section {
  color: #0f1e31 !important;
}

.sbl-action-button {
  padding: 0 !important;
  display: flex !important;
  align-items: center;
  justify-content: center;
  width: 36px !important;
  height: 36px !important;
  background: #e8eef5 !important;
  border-radius: 5px !important;
  -webkit-border-radius: 5px !important;
  -moz-border-radius: 5px !important;
  -ms-border-radius: 5px !important;
  -o-border-radius: 5px !important;
}

.primary.button.sbl-action-button {
  background: #2259a1 !important;
}

.details-header-container .sbl-action-button {
  background: rgba(34, 89, 161, 0.1) !important;
}

.sbl-action-button:hover {
  background: #d8e1ec !important;
}
.sbl-action-button:active {
  background: #c2d2e5 !important;
}

.details-header-container {
  min-height: 110px !important;
  height: auto;
  width: 100% !important;
  padding: 15px !important;
  background: #e4e8f0;
  border-radius: 10px 10px 0 0;
  -webkit-border-radius: 10px 10px 0 0;
  -moz-border-radius: 10px 10px 0 0;
  -ms-border-radius: 10px 10px 0 0;
  -o-border-radius: 10px 10px 0 0;
  margin-bottom: 1px !important;
}

.details-header-title {
  font-size: 22px;
  height: 22px;
  font-weight: 800;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.details-header-subtitle {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  font-size: 13px;
}

.standby-description-details {
  background: #e4e8f0;
  height: 51px;
  overflow: hidden;
  padding: 15px;
  border-radius: 0 0 10px 10px;
  -webkit-border-radius: 0 0 10px 10px;
  -moz-border-radius: 0 0 10px 10px;
  -ms-border-radius: 0 0 10px 10px;
  -o-border-radius: 0 0 10px 10px;
  transition: height 0.5s ease-in-out;
  -webkit-transition: height 0.5s ease-in-out;
  -moz-transition: height 0.5s ease-in-out;
  -ms-transition: height 0.5s ease-in-out;
  -o-transition: height 0.5s ease-in-out;
}

.standby-description-details.active {
  height: auto;
  max-height: 100vh;
}

.standby-description-toggle {
  width: 100%;
  height: 48px;
  font-weight: bold;
  font-size: 16px;
  color: #2259a1;
  position: relative;
}

.standby-description-toggle:hover {
  cursor: pointer;
  color: #2a64b1;
}
.standby-description-toggle:active {
  cursor: pointer;
  color: #123d75;
}

.standby-description-details-arrow {
  position: absolute !important;
  top: 6px;
  right: 0px;
  transform: rotate(0deg);
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transition: transform 0.25s ease-in-out;
  -webkit-transition: transform 0.25s ease-in-out;
  -moz-transition: transform 0.25s ease-in-out;
  -ms-transition: transform 0.25s ease-in-out;
  -o-transition: transform 0.25s ease-in-out;
}

.standby-description-details-arrow.active {
  transform: rotate(180deg) !important;
  -webkit-transform: rotate(180deg) !important;
  -moz-transform: rotate(180deg) !important;
  -ms-transform: rotate(180deg) !important;
  -o-transform: rotate(180deg) !important;
}

.sbl-details-table-header .tabs-menu.menu {
  margin-left: 0 !important;
}

.standby-created-message {
  width: 400px !important;
}

.sbl-created-modal-content {
  height: 135px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.sbl-modal-icon-part {
  height: 135px;
  width: 135px !important;
  min-width: 135px !important;
}

.sbl-modal-icon-part img {
  height: 100%;
  width: 100% !important;
}

.sbl-modal-text-part {
  width: 210px;
  font-size: 14px;
}

.sbl-modal-text-part button {
  width: 195px;
  height: 32px;
  margin-top: 26px !important;
}

.send-message-container {
  background: #dae2ee;
  min-height: 335px;
  width: 100%;
  padding: 1rem;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}

.send-message-form-container {
  width: 600px;
}

.send-message-form-container .standby-dropdown {
  font-size: 13px !important;
}

.send-message-form-container textarea {
  height: 100px;
  resize: none !important;
  padding: 7px 8px !important;
  font-size: 13px !important;
}

.provider-group-container {
  min-height: 29px;
  display: flex;
  align-items: center;
}

.provider-group-container .checkbox {
  margin-right: 1rem;
}

.standby-invite-modal {
  width: 90% !important;
  min-width: 900px !important;
}

.mark-contacted-modal {
  width: 650px !important;
}

.mark-contacted-user-container {
  padding: 12px !important;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: none !important;
  margin-bottom: 2rem !important;
}

.mark-contacted-user-container img {
  width: 36px;
  height: 36px;
  object-fit: cover;
  margin-right: 1rem;
  border-radius: 18px;
  -webkit-border-radius: 18px;
  -moz-border-radius: 18px;
  -ms-border-radius: 18px;
  -o-border-radius: 18px;
}

.mark-contacted-avatar {
  display: flex;
  align-items: center;
  font-weight: 700;
  font-size: 16px;
}

.contacted-button {
  width: 110px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  background: #8497b0;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  color: white;
  font-weight: 700;
}

.contacted-button:hover {
  cursor: pointer;
  user-select: none !important;
}

.mark-contacted-modal textarea {
  resize: none !important;
  height: 135px;
  padding: 10px 8px !important;
  font-size: 13px !important;
}

.standby-invite-modal .check-button {
  margin-right: 0 !important;
}

.selected-tab-headers {
  font-weight: 700;
  font-size: 16px;
  margin-bottom: 30px;
  margin-top: 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.contacted-user {
  color: #8497b0 !important;
  font-size: 13px !important;
}

.standby-details-page .table-email {
  max-width: 130px !important;
}
