
@media (max-width:320px) {

}

@media (max-width: 768px) {

    .ui.register-grid {
        padding-left: 10px !important;
        padding-right: 10px !important;
    }

    .register-buttons-footer {
        padding-top: 0.5rem !important;
        padding-bottom: 0.8rem !important;
    }

    .register-buttons-footer .checkbox {
        /* padding-top: 0.5rem; */
        padding-bottom: 0.7rem;
    }

    .create-education-container {
        width: 100% !important;
    }

    .register-profile-header {
        padding: 0.5em 10px !important;
    }

    .details-grid .remark-label.xactnet {
    width: 100% !important;
    }

    .registration-header-content {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        max-width: 245px;
        width: 50% !important;
        min-width: 115px;
    }

    .register-profile-header h2 {
        font-size: 18px !important;
        font-weight: 900 !important;
        margin-right: 25px !important;
    }

    .profile-completeness .progress-title {
        width: 85px !important;
        font-size: 8px !important;
    }

    .profile-completeness {
        width: 140px !important;
    }

    .header-circular-progress {
        height: 100% !important;
        width: 55px !important;
    }

    .active-tabs-mobile-item {
        height: 50px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-weight: bold;
        color: #2259a1;
        padding: 5.5px 10px ;
        font-size: 16px;
        border-bottom: 4px solid #2259a1;
        margin-bottom: 14px;
    }

    .active-tabs-mobile-item .image {
        transform-origin: center;
        transform: rotate(0);
        -webkit-transform: rotate(0);
        -moz-transform: rotate(0);
        -ms-transform: rotate(0);
        -o-transform: rotate(0);
        transition: transform 0.3s ease-in-out;
        -webkit-transition: transform 0.3s ease-in-out;
        -moz-transition: transform 0.3s ease-in-out;
        -ms-transition: transform 0.3s ease-in-out;
        -o-transition: transform 0.3s ease-in-out;
    }

    .active-tabs-mobile-item .image.rotated {
        transform: rotate(180deg);
        -webkit-transform: rotate(180deg);
        -moz-transform: rotate(180deg);
        -ms-transform: rotate(180deg);
        -o-transform: rotate(180deg);
    }

    .active-tabs-mobile-item .image:hover {
        cursor: pointer;
    }

    .tabs-menu .item:hover {
        color: #2259a1 !important;
        opacity: 0.7 !important;
    }

    .register-adjuster-tabs {
        margin-top: -14px;
        margin-bottom: 14px;
        background: white;
        border-radius: 0 0 5px 5px;
        height: 0px;
        overflow: hidden;
        -webkit-border-radius: 0 0 5px 5px;
        -moz-border-radius: 0 0 5px 5px;
        -ms-border-radius: 0 0 5px 5px;
        -o-border-radius: 0 0 5px 5px;
        transition: height 0.5s ease-in-out;
        -webkit-transition: height 0.5s ease-in-out;
        -moz-transition: height 0.5s ease-in-out;
        -ms-transition: height 0.5s ease-in-out;
        -o-transition: height 0.5s ease-in-out;
    }



    .register-adjuster-tabs.stand-by-tabs.active {
        height: 150px;
    }

    .register-adjuster-tabs.active {
        height: 200px;
    }

    .register-adjuster-tabs .ui.menu.tabs-menu > .item {
        justify-content: left !important;
        padding-left: 10px !important;
    }

    .register-adjuster-tabs .ui.menu.tabs-menu > .item.active {
        display: none;
    }

    .register-details-grid .avatar-input {
        margin-bottom: 14px;
    }

    .register-details-grid .input-field-label,
    .skills-grid .input-field-label {
        min-width: unset !important;
        max-width: unset !important;
    }

    .register-details-grid .input-field-container,
    .adjuster-profile-page .input-field-container,
    .skills-grid .input-field-container {
        display: block !important;
    }

    .details-grid .error.field.form-input {
        width: 100% !important;
    }

    .form-input-dropdown {
        min-height: 32px !important;
    }

    .register-details-grid .checkbox-group,
    .skills-grid .checkbox-group {
        width: 100% !important;
    }

    .register-details-grid .checkbox-group-container,
    .skills-grid .checkbox-group-container {
        width: 100% !important;
    }

    .register-grid .skills-grid .input-field-container,
    .register-grid .register-details-grid .input-field-container {
        width: 100% !important;
    }

    .tablet.only.mobile.only .ui.stackable.grid.details-grid,
    .register-grid .ui.stackable.grid.register-details-grid {
        margin-left: 0 !important;
        margin-right: 0 !important;
        width: 100% !important;
    }

    .register-grid > .row > .ui.stackable.grid.licenses-grid {
        margin-left: 0 !important;
        margin-right: 0 !important;
    }

    .register-grid > .row > .ui.stackable.grid.licenses-grid .tablet.only.mobile.only .column.licenses-column {
        padding-left: 0 !important;
        padding-right: 0 !important;
    }

    .climbing-toogle {
        width: 100% !important;
    }

    .climbing-toogle .toggle-block {
        width: 100%;
    }

    .adjuster-profile-page .input-field-container .zip-form-input {
        width: 100%;
    }

    .zip-form-input .ui.dropdown.form-input-dropdown.form-input-short {
        width: 98% !important;
    }

    .register-grid .row .ui.stackable.grid.register-details-grid {
        margin-left: 0 !important;
        margin-right: 0 !important;
        width: 100% !important;
    }

    .register-grid .row .ui.stackable.grid.register-details-grid > .tablet.only.mobile.only.column {
        padding-left: 0 !important;
        padding-right: 0 !important;
    }
    .tablet.only.mobile.only .ui.stackable.grid.details-grid .row .column{
        padding-left: 0px !important;
        padding-right: 0px !important;
        margin-left: 3px !important;
        margin-right: 3px !important;
    }

    .adjuster-profile-page .field.form-input-dropdown.state-province-dropdown, .adjuster-profile-page .field.form-input-dropdown.state-province-dropdown .ui.dropdown{
        width: 100% !important;
        min-width: 40% !important;
        max-width: 100% !important;
    }


    .adjuster-profile-page .field.form-input-dropdown.state-province-dropdown .ui.dropdown{
        width: 100% !important;
        max-width: 100% !important;
    }

    .experience-block {
        width: 100% !important;
    }

    .experience-block .field.form-input-dropdown {
        width: 70% !important;
        white-space: nowrap;
    }
    .experience-block .error.field.form-input-dropdown {
        display: flex;
        flex-direction: column !important;
    }

    .experience-block .form-input-dropdown.long .ui.dropdown {
        width: 99% !important;
        white-space: nowrap;
    }

    .experience-block .form-input-dropdown.long .ui.dropdown .text {
        max-width: 90% !important;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .experience-block .form-input-years {
        width: 27% !important;
    }

    .experience-block .form-input-years input{
        width: 30px !important;
    }

    .experience-block .form-input-years i{
        font-size: 10px;
        top: 12px !important;
    }

    .form-input-years .error-label-custom {
        white-space: normal !important;
    }

    .register-grid > .row > .ui.stackable.grid.skills-grid {
        margin-left: 0 !important;
        margin-right: 0 !important;
    }

    .skills-grid > div {
        padding-left: 0 !important;
        padding-right: 0 !important;
    }

    .register-grid .skills-grid .toggle-block button {
        width: 50%;
    }

    .expertise-mobile-container {
        display: flex;
        justify-content: space-between;
        width: 100% !important;
    }

    .expertise-mobile-container .form-input-years {
        min-width: 120px;
        flex: 1;
        width: auto !important;
    }

    .expertise-mobile-container > .form-input-dropdown {
        width: 94% !important;
    }


    .expertise-mobile-container > .error.form-input-dropdown,
    .expertise-mobile-container > .error.form-input-dropdown .ui.dropdown {
        min-height: 32px !important;
    }


    .expertise-mobile-container > .error.field.form-input-dropdown .ui.error.dropdown.form-input-dropdown {
        height: 32px !important;
    }



    .form-input-miles {
        margin-bottom: 14px !important;
    }

    .form-input-miles input {
        min-height: 32px !important;
        height: auto !important;
    }

    .experience-block {
        width: 96% !important;
    }

    .responsive-loss-item {
        max-width: 100%;
        width: calc(100% - 25px) !important;
    }

    .expertise-group .first-item-cancel.delete-exp-button {
        top: 43px !important;
    }

    @media (max-width: 480px) {

        .toggle-block {
            width: 100%;
        }

        .toggle-block button {
            width: 50%;
        }

        .licenses-file-preview {
            align-self: flex-start;
            min-width: 100px !important;
        }

        .license-item-container {
            height: auto !important;
        }

        .license-date-container {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            height: 100px;
        }

        .licenses-modal {
            max-width: calc(100vw - 20px) !important;
        }

        .licenses-modal .input-field-container .field.form-input {
            min-width: 20% !important;
        }
    }

    @media (max-width: 665px) {
        .expertise-group .delete-exp-button {
        right: -25px !important;
        }
        .expertise-mobile-full-container .delete-exp-button {
            right: -5px !important;
            top: 30px !important;
        }
    }

    @media (min-width: 666px) and (max-width: 767px) {
        .expertise-group .delete-exp-button {
            right: -25px !important;
            top: 5px !important;
        }
    }

    @media (max-width: 600px) {


        .recovery-modal {
            max-width: calc(100vw - 1rem) !important;
        }

        .recovery-modal .input-field-container .input-field-label{
            min-width: auto !important;
        }

        .recovery-modal .input-field-container .recovery-modal-form-input {
            width: 65% !important;
        }

        .recovery-modal .modal-button-footer button{
            width: max-content !important;
            font-size: 12px;
        }

        .step-button {
            width: 9em !important;
        }
    }

}

@media (min-width: 768px) and (max-width: 950px) {
    .adjuster-profile-page .expertise-loss-container {
        flex-direction: column;
    }
    .adjuster-profile-page .expertise-loss-container > .input-field-label {
        width: 100% !important;
        margin-bottom: 0.5rem;
    }
    .adjuster-profile-page .expertise-loss-container > .responsive-loss-item {
        width: 100% !important;
    }
    .skills-grid .expertise-loss-container {
        flex-direction: column;
    }
    .skills-grid .expertise-loss-container > .input-field-label {
        width: 100% !important;
        margin-bottom: 0.5rem;
    }
    .skills-grid .expertise-loss-container > .responsive-loss-item {
        width: 100% !important;
    }
}

@media (min-width: 769px) and (max-width: 1000px) {

    .expertise-group .delete-exp-button {
        right: -30px !important;
    }
}

.expertise-mobile-full-container {
    position: relative;
}

.register-email-grid {
    margin-bottom: 2rem !important;
}

.register-grid {
    flex-direction: column !important;
    min-height: calc(100vh - 148px);
    padding-left: 24px !important;
    padding-right: 24px  !important;
    margin-left: 0 !important;

    margin-right: 0 !important;
}

.register-grid .ui.grid.stackable {
    padding-left: 0 !important;
    padding-right: 0  !important;
    margin-left: 0 !important;
    margin-right: 0 !important;
}

.register-buttons-footer {
    display: flex;
    min-height: 75px;
    padding: 0 24px;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 100%;
    background: #f7f8fb;
}

.register-details-grid {
    padding-top: 14px !important;
}

.register-grid .form-input-dropdown .ui.dropdown > .dropdown.icon{
    /* width: 14px !important; */
    width: auto !important;
    position: absolute !important;
    right: 5px !important;
    height: auto !important;
}

.step-button {
    width: 19em;
    height: 32px;
    text-transform: uppercase !important;
    padding: 0 !important;
    margin: 0 !important;
}
.cancel {
    color: #2259a1 !important;
    background: #e8eef5 !important
}

.cancel:hover {
    background: #d8e1ec !important
}

.cancel:active {
    background: #c2d2e5 !important
}

.header-row {
    display: block !important;
    height: max-content;
}

.form-input-years .error-label-custom {
    white-space: nowrap;
}

.register-profile-header {
    background: #e4e8f0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    padding-left: 20px;
    padding-right: 20px;
    min-height: 75px;
    width: 100%;
    margin: 0 auto;
    border-radius: 15px;
    -webkit-border-radius: 15px;
    -moz-border-radius: 15px;
    -ms-border-radius: 15px;
    -o-border-radius: 15px;
}

.register-profile-header h2 {
    font-size: 32px;
    margin-right: 25px !important;
}

.register-profile-header-dots {
    color: #cfd5e2;
}

.register-adjuster-tabs .tabs-menu {
    margin: 0 0 1em 0 !important;
}

.register-adjuster-tabs .tabs-menu .item {
    padding-left: 25px !important;
    padding-right: 25px !important;
    justify-content: center !important;
}

.register-adjuster-breadcrumb {
    margin: 1em auto;
}

.register-adjuster-breadcrumb .section {
    margin: 0.2em 0 !important;
}
.register-adjuster-breadcrumb .section.active {
    color: #2259a1 !important;
}

.progress-done {
    color: #2259a1 !important;
}

.checkbox-group-container {
    width: 65%;
}

.profile-completeness {
    display: flex;
    align-items: center;
    width: 155px;
}
.profile-completeness .progress-title {
    width: 100px;
    text-align: right;
    text-transform: uppercase;
    font-weight: bold;
    color: #2259a1;
    font-size: 10px;
    margin-right: 10px;
    line-height: 1.2;
}

.header-circular-progress {
    height: 60px;
    width: 60px;
}

.progress-value-text {
    text-align: center;
    width: 34px;
    line-height: 1;
}
.progress-value-text div {
    height: 15px !important;
    margin-top: 5px;
}

.sidebar.menu {
    background: #f0f2f8 !important;
}

.registration-header-content {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    /* max-width: 245px; */
    /* width: 50% !important; */
    /* min-width: 115px; */
}

.ui.grid.details-grid {
    width: 100% !important;
}

.register-grid .input-field-container {
    width: 90% !important;
    max-width: 100% !important;
}

.details-grid .field.form-input-dropdown.state-province-dropdown, .details-grid .field.form-input-dropdown.state-province-dropdown .ui.dropdown {
    width: 100% !important;
    min-width: 40% !important;
    max-width: 100% !important;
}

.details-grid .input-field-container .form-input.dropdown .form-input-dropdown {
  width: 100% !important;
}


.zip-code-input .form-input-short .error-label-custom {
    white-space: nowrap;
    max-width: 80px;
}

.adjuster-profile-page .field.form-input-dropdown.state-province-dropdown, .adjuster-profile-page .field.form-input-dropdown.state-province-dropdown .ui.dropdown{
    width: 65%;
    min-width: 65% !important;
    max-width: 80%;
}


.adjuster-profile-page .field.form-input-dropdown.state-province-dropdown .ui.dropdown{
    width: 100% !important;
    max-width: 100% !important;
}
.details-grid .field.form-input-dropdown.state-province-dropdown .ui.dropdown{
    width: 98% !important;
    max-width: 100% !important;
}

#avatar-input-column {
    width: 114px !important;
}

#content-register-input-column {
    width: calc(100% - 114px) !important;
}

#content-register-input-column .column {
    min-width: 500px !important;
}

.date-icon {
    top: 7px !important;
    right: -10px !important;
}

.zip-form-input {
    min-width: 65%;
    width: 100%;
    max-width: 100%;
    display: flex;
    justify-content: space-between;
}

.adjuster-profile-page .zip-form-input {
    width: 65%;
}

.zip-form-input .form-input-short {
    min-width: unset !important;
}

.zip-form-input .form-input-dropdown.form-input-short {
    width: 95% !important;
    min-width: 40% !important;
    max-width: 100% !important;
}

.zip-form-input .form-input-short {
    min-width: unset !important;
}

.zip-form-input .zip {
    display: flex;
    max-width: 40%;
}

.zip-form-input .zip .form-input-short {
    width: calc(100% - 30px) !important;
}

.zip-form-input .form-input-short .ui.input {
    width: 80px !important;
    min-width: 80px !important;

}

.create-education-container {
    width: 100%;
    min-width: 65%;
    background: #DADFE9;
    padding: 10px;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
}

.adjuster-profile-page .create-education-container {
    max-width: 65%;
}

.adjuster-profile-page .education-item  {
    max-width: 65% !important;
}

@media (max-width: 768px) {
    .adjuster-profile-page .create-education-container {
        max-width: 100% !important;
    }
    .adjuster-profile-page .education-item  {
        max-width: 100% !important;
    }
}

.form-input-dropdown.long .ui.dropdown, .form-input-dropdown.long .ui.dropdown .visible.menu{
    width: 100% !important;
}

.create-education-container .form-input-dropdown .ui.dropdown{
    width: 100% !important;
}

.create-education-container .form-input-dropdown .ui.dropdown .visible.menu{
    width: 100%;
}

.create-education-container .input-field-container {
    flex-direction: column;
    width: 100% !important;
}

.create-education-container .error.field.form-input {
    width: 100% !important;
}

.education-item {
    position: relative;
    width: 100%;
    min-width: 65%;
    background: #DADFE9;
    padding: 10px;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
}

.education-list {
    display: flex;
    flex-direction: column;
    max-width: 65% !important;
}

.close-education-icon {
    position: absolute;
    right: 15px;
}

.close-education-icon:hover {
    cursor: pointer;
}

.edit-education-icon {
    position: absolute;
    right: 15px;
    top: 35px;
}

.edit-education-icon:hover {
    cursor: pointer;
}

.details-grid .add-question .ui.button, .skills-grid .add-question .ui.button {
    text-align: right !important;
}

.details-grid .readonly-input input, .details-grid .readonly-input .ui.error.input input,
.adjuster-profile-page .readonly-input input, .adjuster-profile-page .readonly-input .ui.error.input input,
.register-grid .readonly-input input, .register-grid .readonly-input .ui.error.input input{
    background: #E4E8F0 !important;
}
.details-grid .readonly-input input:active, .details-grid .readonly-input .ui.error.input input:active,
.adjuster-profile-page .readonly-input input:active, .adjuster-profile-page .readonly-input .ui.error.input input:active,
.register-grid .readonly-input input:active, .register-grid .readonly-input .ui.error.input input:active,
.details-grid .readonly-input input:focus, .details-grid .readonly-input .ui.error.input input:focus,
.adjuster-profile-page .readonly-input input:focus, .adjuster-profile-page .readonly-input .ui.error.input input:focus,
.register-grid .readonly-input input:focus, .register-grid .readonly-input .ui.error.input input:focus,
.details-grid .readonly-input input:hover, .details-grid .readonly-input .ui.error.input input:hover,
.adjuster-profile-page .readonly-input input:hover, .adjuster-profile-page .readonly-input .ui.error.input input:hover,
.register-grid .readonly-input input:hover, .register-grid .readonly-input .ui.error.input input:hover
{
    border: 1px solid transparent !important;
    outline: none !important;
}

.details-grid .error.field.form-input {
    width: 100%;
}

.readonly-input:hover input{
    border: 1px solid transparent !important;
    cursor: auto;
}

.ui.icon.input > input[type='date'] {
    padding-right: 8px !important;
}

.details-grid .remark-label {
    width: 100%;
}

.details-grid .remark-label.xactnet {
    width: calc(100% - 195px);
}

.experience-block {
    display: flex;
    position: relative;
    width: 65%;
    justify-content: space-between;
}

.experience-block .field {
    margin: 0 !important;
}

.climbing-toogle {
    display: flex;
    width: 65%;
}

.checkbox-group-container .checkbox-group {
    width: 100% !important;
}

.checkbox-group .checkbox {
    margin-bottom: 5px !important;
}
.checkbox-group .checkbox:last-child {
    margin-bottom: 0px !important;
}

.checkbox-group .error.field {
    display: flex;
    flex-direction: column;
}

.delete-exp-button {
    position: absolute;
    top: 5px;
    right: -25px;
}

.register-part-grid > div {
    max-width: 65%;
}

.skills-grid.register-part-grid > div {
    max-width: 74%;
}

@media (max-width: 1100px) {

.register-part-grid > div {
    max-width: 100%;
}
.skills-grid.register-part-grid > div {
    max-width: 100%;
}
}

.expertise-group {
    display: flex;
    position: relative;
    justify-content: space-between;
    /* max-width: 65% !important; */
    max-width: calc(100% - 0px) !important;
}

.expertise-group > div {
    margin-right: 2px;
    margin-left: 2px;
}

.expertise-group .delete-exp-button {
    right: -25px !important;
}

.expertise-group > div > label {
    max-width: 100px !important;
}

.expertise-group .field.form-input-dropdown {
    height: 32px !important;
    max-width: 100% !important;
}

.expertise-group .field.form-input-dropdown.error {
    min-height: 32px !important;
    height: unset !important;
}

.register-part-grid .expertise-group .form-input-dropdown {
    min-height: 32px !important;
}

.first-item-cancel {
    top: 43px !important;
}

.miles-input {
    display: flex;
    width: 100%;
}

.ui.grid > [class*="one column"].row > .column.licenses-column,
.ui.grid > [class*="sixteen column"].row > .column.licenses-column
 {
    max-width: 450px !important;
    width:100% !important;
    padding: 0 !important;
}

.licenses-column .add-question button,
.adjuster-profile-page .add-question button {
    text-align: right !important;
}

.licenses-modal {
    width: 400px !important;
}

.licenses-modal .input-field-label {
    min-width: 110px !important;
    max-width: 110px !important;
    padding-top: 10px;
}

.licenses-modal .input-field-container {
    padding-left: 1rem;
    padding-right: 1rem;
}

.licenses-modal .input-field-container .field.form-input-dropdown,
.licenses-modal .input-field-container .field.form-input {
    width: 240px !important;
    min-height: 32px !important;
    height: auto !important;
}

.licenses-modal .input-field-container .form-input-short {
    width: 240px !important;
}

.licenses-modal .input-field-container .form-input-dropdown .ui.dropdown.form-input-dropdown {
    width: 100% !important;
}

.licenses-modal .input-field-container .form-input-dropdown .ui.dropdown.form-input-dropdown .visible.menu {
    width: 240px !important;
}

.drop-licenses-area {
    width: calc(100% - 2rem);
    height: 90px;
    margin: 0 auto !important;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: #dadfe9 !important;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
    color: #8497b0;
}

.drop-licenses-area .add-question {
    justify-content: center !important;
}

.responsive-loss-item {
    max-width: 100%;
    width: calc(100% - 195px);
}

.adjuster-profile-page .skills-grid .responsive-loss-item {
    width: calc(100% - 175px);
}

.licenses-file-preview {
    width: 100px;
    height: 100px;
    position: relative;
    margin-left: 1rem;
}

.license-item-container .licenses-file-preview {
    margin-left: 0 !important;
}

.licenses-file-preview .iconic-button-custom {
    position: absolute;
    top: 0;
    right: -25px;
}

.licenses-file-preview .iconic-button-custom .image {
    width: unset !important;
    height: unset !important;
    overflow: unset !important;
}

.licenses-file-preview img {
    width: 100px;
    height: 100px;
    object-fit: cover;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
}

.pdf-file-container {
    background: #a00e0e;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    color: white;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
}

.doc-file-container {
    background: #2a70cb;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    color: white;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
}

.license-item-container {
    margin-bottom: 5px;
    min-height: 120px;
    height: auto;
    max-width: 450px !important;
    display: flex;
    align-items: center;
    background: #dadfe9;
    padding: 10px;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
    position: relative;
}

.license-item-info {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 250px;
    height: 100%;
    min-height: 100px;
    margin-left: 20px;
}

.license-item-info > div {
    display: flex;
    justify-content: space-between;
    width: 100% !important;
}

.no-image-placeholder {
    width: 100%;
    height: 100%;
    min-width: 100px;
    background: #e4e8f0;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #8497b0;
    font-size: 13px;
}

.license-item-container .buttons-container {
    position: absolute !important;
    right: 10px !important;
    top: 10px;
    display: flex;
    flex-direction: column;
    height: 100px;
}

.license-item-container .buttons-container .iconic-button-custom {
    margin-bottom: 5px !important;
}

.social-login-block {
    display: flex;
    justify-content: space-between;
    margin: 1rem auto;
    width: 250px;
}

.social-button {
    width: 75px !important;
    height: 32px;
    padding: 0 !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
}

.social-button img {
    height: 20px;
    width: 20px ;
}

.fb-button {
    background:#3b5998 !important;
}

.fb-button:hover {
    background:#4261a2 !important;
}

.fb-button:active {
    background:#2f4b86 !important;
}

.g-button {
    background:#dd4b39 !important;
}

.g-button:hover {
    background:#e75340 !important;
}

.g-button:active {
    background:#c03726 !important;
}

.in-button {
    background:#007ab9 !important;
}

.in-button:hover {
    background: #0389ce !important;
}

.in-button:active {
    background: #006599 !important;
}

.skills-grid .input-field-container {
    margin-bottom: 10px !important;
}

.phone-field-container, .code-field-container {
    display: flex !important;
    padding: 0 1rem;
    max-width: 450px;
}

.code-field-container .form-input {
    flex-direction: column;
    display: flex;
    width: 100% !important;
}

.phone-page-label {
    display: block;
    width: 150px !important;
    min-width: 150px !important;
}

@media (max-width: 768px) {
    .phone-field-container, .code-field-container {
        flex-direction: column;
    }

    .code-field-container .form-input, .phone-field-container, .phone-input-container,
    .phone-page-label
    {
        width: 100% !important;
    }
}

.code-field-container .form-input .ui.input {
    width: 100% !important;
}

.phone-input-container {
    width: 65%;
    display: flex;
}

.phone-input-container .form-input.form-input-dropdown {
    min-width: 40% !important;
    width: 40% !important;
}
.phone-input-container .form-input.form-input-dropdown .ui.dropdown {
    min-width: 40% !important;
    width: 95% !important;
}

.phone-input-container .form-input.phone-input {
    width: 100% !important;
}

.time-counter-container {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 20px;
    color: #2259a1;
    font-size: 14px;
}

.expertise-group .error-label-custom {
    white-space: nowrap !important;
}

.expertise-group > div {
    width: 19% !important;
}

.details-grid .input-field-container {
    max-width: 800px !important;
    margin-left: auto;
    margin-right: auto;
}

.register-grid .skills-grid .input-field-container {
    margin-left: auto;
    margin-right: auto;
}

.register-grid .input-field-label {
    min-width: 195px !important;
}

.register-grid .form-input, .register-grid .form-input-short, .register-grid .checkbox-group-container,
.register-grid .create-education-container {
    width: 100%;
    min-width: 65%;
}

.register-grid .experience-block {
    width: calc(100% - 20px) !important;
}

.register-grid .skills-grid .expertise-group .required.field {
    width: 100% !important;
    /* max-width: 130px; */
}

.register-grid .skills-grid .expertise-group .form-input-dropdown .ui.dropdown {
    min-width: 0 !important;
}

.thanks-page .logo-container h3 {
    line-height: 2;
}


@media (max-width: 525px) {
    .thanks-page .logo-container h3 {
        line-height: 1.2;
    }
    .thanks-page .logo-container {
        height: 350px;
    }
}
