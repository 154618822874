@media (max-width:320px) {
    
}

@media (max-width: 768px) {
    .main-header {
        text-align: unset !important;
        padding-left: 4px;
        padding-right: 4px;
        display: flex;
        justify-content: space-between;
    }
    .header-menu.register-header-mobile {
        width: max-content !important;
        display: flex;
        align-items: center;
        padding: 0 7px !important;
        margin: 0 !important;
    }
    .header-logo-container {
        display: block;
        width: 60px;
        height: 45px !important;
        margin-top: 4px;
        margin-bottom: 4px;
    }
    .header-logo-container img {
        width: 100px;
        height: 45px !important;

    }
@media (max-width: 768px) {
    .header-logo-container img {
        display: none !important;
    }
}
@media (max-width: 600px) {
    .header-logo-container img {
        display: block !important;
    }
}
    .register-header-mobile .ui.dropdown .visible.menu > .item {
        padding: 6px 0 !important;
    }
}

@media (max-width: 992px) {

}

@media (min-width: 992px) {
    
}

.register-dropdown .visible.menu{
        width: 108px !important;
        min-width: max-content !important;
        left: -100px !important;
        top: 5px !important;
        box-shadow: unset !important;
        border: none !important;
        padding: 10px 14px !important; 
}

.register-dropdown .visible.menu > .item{
        font-weight:  normal !important;
        font-size: 13px !important;
}   

.main-header {
    min-height: 45px;
    text-align: center;
}

.main-header .position-left {
    display: inline-block;
    position: absolute;
    left: 0;
}

.main-header .position-right {
    display: inline-block;
    position: absolute;
    right: 0;
}

.header-logo-container {
    display: inline-block;
    width: 135px;
    height: 60px;
    margin-top: 4px;
    margin-bottom: 4px;
}

.main-header  .menu .item {
    border-radius: 0 !important;
    -webkit-border-radius: 0 !important;
    -moz-border-radius: 0 !important;
    -ms-border-radius: 0 !important;
    -o-border-radius: 0 !important;
}

.account-header {
    padding: 8px 0 !important;
    width: 100%;
}

.account-header .ui.grid {
    margin-left: 0 !important;
}

.account-header > .header-menu {
    height: 100px;
    justify-content: center;
    align-items: center;
}

.profile-dropdown.dropdown .menu.visible {
    top: 4em !important;
    left: -3.5em !important;
}

.header-menu {
    height: 45px;
    box-shadow: none !important;
    border: none !important;
    background: #f0f2f8 !important;
}

.header-menu .item {
    color: #2259a1 !important;
    font-weight: bold !important;
    font-size: 16px;
    padding: 1rem 2rem !important;
}

.active-route-header {
    border-bottom: 4px solid #2259a1;
}

.account-header-title-container {
    display: flex !important;
    justify-content: space-between !important;
    align-items: center !important;
    height: 100% !important;
    padding-left: 1rem !important;
    margin-top: 0.4rem !important;
    width: calc(100% - 242px) !important;
}

.account-header-title {
    font-size: 32px !important;
    color: #0f1e31 !important;
    font-weight: 800;
    line-height: 1 !important;
    padding-top: 1rem;
    padding-bottom: 1rem;
}

.header-user-avatar {
    height: 54px;
    width: 54px;
    object-fit: cover !important;
    margin-left: 2px;
}

.header-user-avatar img {
    display: block;
    margin: auto;
    width: 100%;
    height: 100%;
    object-fit: cover !important;
}

.header-user-avatar img {
    display: block;
    margin: auto;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.account-header-logo {
    width: 235px !important;
}

.account-header-logo > div {
    display: flex;
    justify-content: center;
}

.account-header-logo > div:hover {
    cursor: pointer;
}

.back-button {
    margin-right: 16px !important;
    height: 36px;
    width: 36px;
    padding: 0 !important;
    display: flex !important;
    align-items: center;
    justify-content: center;
    border-radius: 5px !important;
    -webkit-border-radius: 5px !important;
    -moz-border-radius: 5px !important;
    -ms-border-radius: 5px !important;
    -o-border-radius: 5px !important;
}