.ui.table.data-table {
  border: none;
  background-color: transparent;
}

.ui.table.data-table thead th {
  border-bottom: none;
  background-color: transparent;
  font-size: 12px;
  font-weight: 100;
  color: #93A4BB;
  padding-top: 5px;
  padding-bottom: 5px;
}

.ui.table.data-table > tbody td {
  color: #0E1E31;
  font-size: 13px;
  font-weight: 100;
  padding-top: 4px !important;
  padding-bottom: 4px !important;
}

.ui.table.data-table .action-col .ui.button {
  background-color: transparent !important;
}

.ui.table.data-table > tbody tr {
  background-color: #E5E8F1;
}

.ui.table.data-table > tbody tr:nth-child(2n) {
  background-color: transparent !important;
}