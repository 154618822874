.searchselection-container {
  position: relative;
  flex: 1;
  display: flex;
}
.searchselection-container .ui.selection.dropdown.searchselection-input {
  min-height: 33px;
  flex: 1;
  min-width: 0;
}

.searchselection-container .ui.inline.dropdown .dropdown.icon {
  margin: -7px;
  right: 16px !important;
}

.searchselection-container .ui.search.dropdown > .text {
  top: 1px;
  font-weight: 100;
  overflow: hidden;
  white-space: nowrap;
  line-height: 1.1;
  padding-right: 20px;
}

.searchselection-container .ui.search.dropdown > .default.text {
  color: #8FA0B7 !important;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 1.1;
  padding-right: 20px;
}

.searchselection-container .search-icon {
  position: absolute;
  z-index: 100;
  top: 8px;
  left: 8px;
}

.searchselection-container > .ui.selection.dropdown.searchselection-input {
  padding-left: 30px;
}
.searchselection-container > .ui.selection.dropdown.active.searchselection-input > .menu {
  border: none;
}

.searchselection-container .ui.selection.dropdown.searchselection-input > input.search {
  padding-left: 30px;
  outline: none !important;
}

.searchselection-container .ui.selection.dropdown.searchselection-input .menu > .item {
  white-space: nowrap;
}