@media (max-width: 768px) {
.sidebar-menu-container {
        width: 100% !important;
        min-width: 100% !important;
        padding: 8px !important;
        min-height: 0 !important;
        max-height: 100% !important;
        height: 20rem !important;
        overflow: hidden;
        transition: height 0.5s ease-in-out;
        -webkit-transition: height 0.5s ease-in-out;
        -moz-transition: height 0.5s ease-in-out;
        -ms-transition: height 0.5s ease-in-out;
        -o-transition: height 0.5s ease-in-out;
    }
    .sidebar-menu-container:focus {
        outline: none !important;
    }
    .sidebar-menu-container.short {
        height: 56px !important;
        padding: 8px !important;
    }
    .sidebar-menu-container .ui.vertical.menu {
        width: 100% !important;
    }
    .sidebar-menu-container .sidebar-menu .item {
        position: relative;
        width: 100% !important;
        height: 40px !important;
        padding-top: 0 !important;
        padding-bottom: 0 !important;
        display: flex !important;
        align-items: center !important;
        flex-direction: row !important;
    }
    .collapse-icon {
        position: absolute;
        right: 0;
        top: 10px;
        opacity: 0.4 !important;
    }
    .sidebar-container-column {
        max-width: 100% !important;
    }
    .sidebar-container-column > div {
        width: 100% !important;
    }
    .collapse-icon {
        position: absolute;
        right: 0;
        top: 10px;
        opacity: 0.4 !important;
    }
}

.sidebar-menu {
    background: transparent !important;
    box-shadow: unset !important;
    border: none !important;
    align-self: center;
    width: 200px !important;
}

.sidebar-menu .item {
    color: white !important;
    font-family: 'Montserrat';
    height: 50px !important;
    line-height: 20px !important;
    width: 200px !important;
    font-size: 16px;
    margin: 0 !important;
    border-radius: 8px !important;
    -webkit-border-radius: 8px !important;
    -moz-border-radius: 8px !important;
    -ms-border-radius: 8px !important;
    -o-border-radius: 8px !important;
}

.sidebar-menu .item:hover {
    background: rgba(255,255,255,0.1) !important;
}

.sidebar-menu .item:before {
    height: 0px !important;
}

.sidebar-menu .active.item {
    background: rgba(255,255,255,0.1) !important;
}

.sidebar-menu .item > .icon {
    float: left !important;
    width: 27px;
    height: 23px;
    margin-right: 12px !important;
    margin-left: 0 !important;
    font-size: 23px !important;
}

.sidebar-menu-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background: #2259a1;
    background-image: url('../images/sidebar-background.png') !important;
    background-repeat: no-repeat ;
    background-position: 68% 0% !important;
    background-size: cover;
    border-radius: 15px !important;
    -webkit-border-radius: 15px !important;
    -moz-border-radius: 15px !important;
    -ms-border-radius: 15px !important;
    -o-border-radius: 15px !important;
    padding: 12px !important;
    margin: 0;
    min-height: calc(100vh - 123px);
    height: 100%;
    min-width: 225px !important;
    width: min-content;
}

.sidebar-menu-container .ui.vertical.menu {
    display: flex;
    align-items: center;
}

.sidebar-menu-container p {
    margin: 0px;
    color: white;
    font-size: 10px;
    opacity: 0.5;
    padding-left: 20px;
    font-family: 'Montserrat';
}

.sidebar-container-column {
    display: flex !important;
    flex-direction: column !important;
    align-items: center !important;
    max-width: 235px;
    width: 235px !important;
    padding-left: 1em !important;
}

.content-container-column {
    width: calc(100% - 242px) !important;
    padding-left: 1rem !important;
}