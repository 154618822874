.dashboard {
    height: 100%; 
}

.dashboard .ui.stackable.grid.dashboard-content-grid {
    height: calc(100% - 70px);
}

.ui.stackable.grid.dashboard-content-grid .eight.wide.column {
    height: 50% !important;
}

.dashboard-block {
    height: 100%;
}

.dashboard-header-grid {
    width: 100%;
    margin-bottom: 20px !important;
}

.dashboard-header-grid .column {
    height: 50px !important;
    padding: 15px 0;
}

.dashboard-title {
    font-size: 16px;
    font-family: 'Montserrat';
    font-weight: bold;
    line-height: 19px;
}

.dashboard-title > span {
    float: right;
    font-weight: normal;
    font-size: 32px;
}

.dashboard-header-grid .button {
    height: 30px;
    width: 120px;
    text-transform: uppercase !important;
    padding: 0 !important;
    margin-top: -15px !important;
    margin-left: 10px !important;
}

.dashboard .segment {
    border: none !important;
    box-shadow: none !important;
    margin-bottom: 0.5rem !important;
    margin-top: 0.5rem !important;
    border-radius: 10px !important;
    -webkit-border-radius: 10px !important;
    -moz-border-radius: 10px !important;
    -ms-border-radius: 10px !important;
    -o-border-radius: 10px !important;
}

.dashboard-block-header {
    display: flex;
    justify-content: space-between;
    font-size: 20px;
    font-family: 'Montserrat';
    font-weight: bold;
    line-height: 30px;
    height: 30px;
}

.dashboard-block-header .button {
    height: 30px;
    width: 120px;
    padding: 0 !important;
}

.segment-layout {
    display: flex;
    font-family: 'Montserrat';
}

.segment-title {
    margin-top: -5px !important;
    font-size: 16px;
    margin-bottom: 0;
    font-weight: bold;
}

.segment-description {
    font-size: 12px;
}

.segment-user-info {
    padding-left: 5px;
}