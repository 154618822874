.adjuster-standby-list .adjusters-list-header .ui.search {
    margin-right: 0 !important;
}

.adjuster-standby-list .sort-field .ui.dropdown .visible.menu {
    width: 182px !important;
    max-width: 182px !important;
    min-width: 0 !important;
}

.sbl-details-table-header .tabs-menu.menu {
  margin-left: 0 !important
}

.sbl-item-container {
    min-height: 60px !important;
    height: auto !important;
    border: none !important;
    box-shadow: 0 0 0 0 !important;
    padding: 12px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-between;
}

.sbl-item-container > div {
    display: flex;
    flex-wrap: wrap;
}

.sbl-item-title {
  margin-left: 12px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 400px;
}

.sbl-item-title > div {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 100%; 
}

.sbl-item-dates-section {
  color: #8497b0;
  font-size: 13px;
  text-align: right;
  max-width: 235px;
  min-width: 215px;
}

.adjuster-standby-item-button {
  padding: 0 !important;
  display: flex !important;
  align-items: center;
  align-self: flex-end !important;
  justify-content: center;
  height: 36px !important;
  width: 171px !important;
  font-size: 14px !important;
  margin-left: 14px !important;
}

.sbl-item-title-container {
  max-width: calc(100% - 595px) !important;
  flex-wrap: nowrap !important;
}

.sbl-item-title-container img {
  height: 36px !important;
  width: 36px !important;
  min-width: 36px;
}

.sbl-item-title-container .sbl-item-title {
  max-width: 100% !important;
}

.sbl-item-info-container {
  flex-wrap: wrap;
  min-width: 585px;
  justify-content: flex-end;

}

.sbl-item-info-container .sbl-item-title {
  margin-left: 0 !important;
}

.stbl-adjuster-item .sbl-item-dates-section {
  margin-left: 1rem;
}

.standby-question-adjuster {
  height: auto !important;
  flex-wrap: wrap;
}

.standby-question-adjuster .question-text {
  font-size: 16px;
  font-weight: 700;
  width: calc(100% - 360px) !important;
}

@media (min-width:925px) and (max-width: 1050px) {
  .standby-question-adjuster .question-text {
    width: calc(100% - 50px) !important;
  }
}
.standby-question-adjuster .question-text.long-title {
  font-size: 16px;
  font-weight: 700;
  width: calc(100% - 58px) !important;
}

.standby-question-adjuster .question-type {
  width: 280px;
}

.standby-question-adjuster .question-type .toggle-block{
  width: 100% !important;
}

.standby-question-adjuster .question-type .toggle-block button{
  width: calc(50% - 1px) !important;
  margin-right: 1px !important;
}

.standby-question-input-block {
  width: 100% !important;
  min-width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 0.5rem;
}

.standby-question-input-block .standby-question-input {
  width: calc(100% - 58px) !important;
  margin-top: 1rem;
}

.standby-question-adjuster .standby-question-input label {
  display: none !important;
}


@media (max-width: 768px) {
  
  .standby-list-mobile .sort-field {
    width: 100% !important;
    margin-bottom: 0.3rem;
  }

  .standby-list-mobile .sort-field .ui.dropdown {
    width: calc(100% - 55px) !important;
    max-width: 100% !important;
  }

  .standby-list-mobile .sort-field .ui.dropdown .visible.menu{
    width:calc(100% + 60px) !important;
    max-width: calc(100% + 60px) !important;
  }

  .standby-list-mobile .ui.search .ui.input {
    width: 100%;
  }

  .details-header-title {
    font-size: 18px !important;
    margin-bottom: 0.8rem;
  }

    .details-header-container .sbl-item-dates-section {
    text-align: left !important;
    margin-right: 1rem;
    margin-bottom: 1rem;
  }

  .details-header-container .sbl-item-title {
    margin-left: 0 !important;
  }

}

@media (max-width: 1050px) {
  .sbl-item-container {
    flex-direction: row !important;
    align-items: flex-start !important;
    justify-content: flex-start;
  }

  .standby-mobile-item-info {
    max-width: calc(100% - 48px);
    flex: 1;
    justify-content: space-between;
  }

  .standby-mobile-item-info > div {
    margin-bottom: 0.5rem;
  }

  .standby-mobile-item-info .standby-dates-info > div {
    margin-bottom: 0.5rem;
  }

  .standby-mobile-item-info .sbl-item-dates-section {
    text-align: left !important;
  }

  .standby-mobile-item-info .sbl-item-title.standby-applied-block {
    text-align: left !important;
  }

  .standby-mobile-item-info .sbl-item-title.standby-applied-block .applied-block-info {
    text-align: left !important;
  }

  .standby-mobile-item-info .adjuster-standby-item-button {
    width: 100% !important;
    max-width: 400px;
  }

  .standby-adjuster-question-body {
    display: flex;
    flex-direction: column;
    max-width: calc(100% - 55px);
    width: 100%;
  }

  .standby-adjuster-question-body .question-text {
    margin-bottom: 1rem;
    width: 100% !important;
  }

  .standby-adjuster-question-body .standby-question-input-block .standby-question-input {
    width: 100% !important;
  }
   .question-type {
     width: 100% !important;
     max-width: 500px !important; 
    }

    @media (min-width: 925px) and (max-width: 1050px) {
      .question-type {
        width: calc(100% - 50px) !important;
        margin-left: 50px;
      }
      .standby-question-input-block .standby-question-input {
        width: calc(100% - 50px) !important;
        margin-left: 50px !important;
      }
    }
}

  .question-order {
    align-self: flex-start;
  }

.standby-applied-block {
  min-width: 180px;
}
