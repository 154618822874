.files-grid-row {
  flex-direction: column !important;
}

@media only screen and (min-width: 600px) {
  .files-grid-row {
    flex-direction: row !important;
  }
}

@media only screen and (min-width: 600px) {
  .switch-view, .folder-list {
    width: 18.75% !important;
  }
}
