.ui.form .fields.error .field textarea, .ui.form .fields.error .field select, .ui.form .fields.error .field input:not([type]), .ui.form .fields.error .field input[type="date"], .ui.form .fields.error .field input[type="datetime-local"], .ui.form .fields.error .field input[type="email"], .ui.form .fields.error .field input[type="number"], .ui.form .fields.error .field input[type="password"], .ui.form .fields.error .field input[type="search"], .ui.form .fields.error .field input[type="tel"], .ui.form .fields.error .field input[type="time"], .ui.form .fields.error .field input[type="text"], .ui.form .fields.error .field input[type="file"], .ui.form .fields.error .field input[type="url"], .ui.form .field.error textarea, .ui.form .field.error select, .ui.form .field.error input:not([type]), .ui.form .field.error input[type="date"], .ui.form .field.error input[type="datetime-local"], .ui.form .field.error input[type="email"], .ui.form .field.error input[type="number"], .ui.form .field.error input[type="password"], .ui.form .field.error input[type="search"], .ui.form .field.error input[type="tel"], .ui.form .field.error input[type="time"], .ui.form .field.error input[type="text"], .ui.form .field.error input[type="file"], .ui.form .field.error input[type="url"], .ui.form .fields.error .field .ui.dropdown, .ui.form .fields.error .field .ui.dropdown .item, .ui.form .field.error .ui.dropdown, .ui.form .field.error .ui.dropdown .text, .ui.form .field.error .ui.dropdown .item {
    background: white !important;
    border-color: transparent !important;
}

.ui.form .fields.error .field .input input::placeholder, .ui.form .field.error .input input::placeholder,
.ui.form textarea::placeholder, .ui.form .field.error textarea::placeholder {
    color: #8497b0 !important;
    font-family: 'Montserrat'
}

.ui.form textarea {
    color: #0f1e31 !important;
    font-family: 'Montserrat'
}



.ui.dropdown > .dropdown.icon:before {
    content: url(../images/ui-dropdown.svg) !important;
}

.ui.pagination.menu {
    min-height: 0 !important;
    background: none !important;
    border: none !important;
    box-shadow: none !important;
}

.ui.pagination.menu .item{
    display: flex;
    outline: none !important;
    justify-content: center;
    font-weight: 800 !important;
    text-align: center !important;
    border: none !important;
    padding: 0 !important;
    padding-left: 11px !important;
    padding-right: 11px !important;
    margin-left: 5px;
    margin-right: 5px;
    height: 32px !important;
    color: #2259a1 !important;
    background: #e4e8f0 !important;
    border-radius: 15px !important;
    -webkit-border-radius: 15px !important;
    -moz-border-radius: 15px !important;
    -ms-border-radius: 15px !important;
    -o-border-radius: 15px !important;
}

.ui.pagination.menu .item:hover {
    background: #d8e1ec !important;
}

.ui.pagination.menu .item:active {
    background: #c2d2e5 !important;
}

.ui.pagination.menu .item.active {
    color: white !important;
    background: #123d75 !important;
}

.ui.pagination.menu .item.active:hover {
    background: #2a64b1 !important;
}

.ui.pagination.menu .item.active:active {
    background: #123d75 !important;
}

.ui.pagination.menu .item::after, .ui.pagination.menu .item::before  {
    content: unset !important;
}

.ui.table tr td { border-top: 0px !important; }

.ui.checkbox .box:before, .ui.checkbox label:before {
    background: url(../images/Checkbox/ch-box-0-norm.svg) no-repeat !important;
    background-position: center !important;
    border: none !important;
    outline: none !important;
}

.ui.checkbox .box:before, .ui.checkbox:hover label:before {
    background: url(../images/Checkbox/ch-box-0-hover.svg) no-repeat !important;
    background-position: center !important;
    border: none !important;
    outline: none !important;
}

.box:after, .ui.checkbox input:checked ~ label:after {
    background: url(../images/Checkbox/ch-box-1-norm.svg) no-repeat !important;
    background-position: center !important;
    color: transparent !important;
}
.box:after, .ui.checkbox:hover input:checked ~ label:after {
    background: url(../images/Checkbox/ch-box-1-hover.svg) no-repeat !important;
    background-position: center !important;
    color: transparent !important;
}

.box:after, .ui.checkbox:active input:checked ~ label:after, .ui.checkbox .box:before, .ui.checkbox:active label:before{
    background: url(../images/Checkbox/ch-box-10-pressed.svg) no-repeat !important;
    background-position: center !important;
    color: transparent !important;
}

.create-admin-modal {
    width: 700px !important;
}
.create-admin-modal .header{
    border-bottom: none !important;
    padding: 1em !important;
    padding-bottom: 0 !important;
    font-size: 16px !important;
    color: #57616e !important;
    background: #f0f2f8 !important;
}

.create-admin-modal .content {
    background: #f0f2f8 !important;
    padding: 1em !important;
    padding-bottom: 0 !important;
}

.admin-list-header {
    display: flex;
    flex-wrap: wrap;
    height: 50px;
    width: 100%;
    padding: 0;
    padding-left: 0.5em;
    justify-content: space-between;
}

.admin-list-header .ui.text.menu {
    margin: 0 !important;
}

.sort-field {
    border-radius:5px;
    height: 32px;
    width: 200px;
    align-self: center;
    line-height: 30px;
    padding-left: 5px;
    font-size: 13px;
    color: #0f1e31;
    background: white;
    -webkit-border-radius:5px;
    -moz-border-radius:5px;
    -ms-border-radius:5px;
    -o-border-radius:5px;
}

.sort-field .ui.dropdown {
    width: 140px;
}

.sort-field .ui.dropdown .text{
    font-weight: 400 !important;
    color:#0f1e31 !important;
}

.sort-field .ui.dropdown .icon {
    right: 0 !important;
    top: 10px;
    position: absolute !important;
}

.sort-field .ui.dropdown .visible.menu {
    width: 180px !important;
    margin-left: -58px;
}

.admin-list-header .ui.search {
    margin-right: 10px;
    margin-left: 3px;
    height: 32px !important;
    width: 180px !important;
    align-self: center;
}

/* .admin-list-header .ui.search i { */
.ui.search i {
    height: 32px !important;
    width: 32px !important;
    top: 8px !important;
    left: 8px !important;
}

/* .admin-list-header .ui.search input { */
.ui.search input {
    height: 32px;
    width: 180px;
    border: 1px solid transparent;
    border-radius: 5px !important;
    -webkit-border-radius: 5px !important;
    -moz-border-radius: 5px !important;
    -ms-border-radius: 5px !important;
    -o-border-radius: 5px !important;
}

.add-admin-button {
    height: 32px;
    align-self: center;
    color: white !important;
    background: #8b664c !important;
}
.add-admin-button:hover {
    background: #a77e61 !important;
}
.add-admin-button:active {
    background: #78553c !important;
}

.tabs-menu {
    margin-top: 0 !important;
}

.tabs-menu .item {
    font-weight: 700 !important;
    color: #57616e !important;
    height: 50px;
    font-size: 16px;
}

.tabs-menu .item:hover {
    color: #2259a1 !important;
}

.tabs-menu .item.active {
    color: #2259a1 !important;
    border-bottom: 4px solid #2259a1 !important;
}

.gear-button {
    height: 36px;
    width: 36px;
    margin-left: 14px !important;
    padding: 0 !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    border-radius: 5px !important;
    -webkit-border-radius: 5px !important;
    -moz-border-radius: 5px !important;
    -ms-border-radius: 5px !important;
    -o-border-radius: 5px !important;
}

.gear-button .image {
    margin: 0 !important;
    height: 20px !important;
    width: 20px !important;
}

.admin-list-table .ui.segment {
    box-shadow: none !important;
    border: none !important;
    cursor: pointer;
}

.segment-admin-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100%;
    box-shadow: none !important;
    padding: 0px;
}

.segment-admin-container > div {
    display: flex;
    align-items: center;
    padding: 0 ;
    padding-right: 15px;
}

.segment-admin-container > div > div {
    margin: 0 !important;

}

.segment-admin-container > div > div:not(last-child), .segment-admin-container > div > div:not(first-child) {
    margin: 0 !important;
}

.checkbox-custom-container {
    padding-left: 15px !important;
    padding-right: 10px !important;
    height: 60px;
    display: flex;
    align-items: center;
}

.admin-list-avatar {
    margin-right: 10px;
    object-fit: cover !important;
}

.segment-admin-container .image {
    height: 36px;
    width: 36px;
}

.segment-admin-dropdown .menu.visible {
    left: -95px !important;
    top: 8px !important;
}

.admin-list-table table{
    margin-top: 10px !important;
    background: none !important;
    border-collapse: separate !important;
    border: none !important;
}

.admin-list-table table td {
    padding: 0 !important;
    padding-bottom: 8px !important;
}

.admin-list-table table .segment {
    height: 60px !important;
    padding: 0 !important;
}

.admin-fullname-container {
    display: flex;
    align-items: center;
    font-weight: 800;
    font-size: 16px;
    color: #0f1e31;
}

.status-badge {
    text-transform: uppercase;
    height: 15px;
    font-weight: 400;
    color: white;
    font-size: 10px;
    padding: 0 5px;
    line-height: 15px;
    margin-left: 6px;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
}

.status-badge.active {
    background: #1cb800;
}
.status-badge.inactive {
    background: #8497b0;
}

.modal-button-footer {
    padding-left: 10px;
    padding-right: 10px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 75px;
    background: #f7f8fb;
}

.secondary-button {
    text-transform: uppercase !important;
    background-color: #e8eef5 !important;
    color: #2259a1 !important;
}

.secondary-button:hover {
    background-color: #d8e1ec !important;
}

.secondary-button:active {
    background-color: #c2d2e5 !important;
}

.bulk-header {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    height: 40px;
    line-height: 40px;
    font-size: 16px;
    font-weight: 700;
    padding-left: 1rem;
    padding-right: 1rem;
}
.confirm-modal-custom .header {
    border-bottom: none !important;
    padding: 1rem !important;
    padding-bottom: 0 !important;
    font-size: 16px !important;
    background: #f0f2f8 !important;
    color: #57616e !important;
}

.confirm-modal-custom .content {
    background-color: #f0f2f8 !important;
    padding: 1rem !important;
}

.confirm-modal-custom .actions {
    display: flex !important;
    justify-content: space-between;
    background-color: rgb(247, 248, 251) !important;
    border-top: none !important;
}

.confirm-modal-custom .actions button {
    width: 100px;
}

.confirm-modal-custom .actions button:first-child {
    text-transform: uppercase !important;
    background-color: #e8eef5 !important;
    color: #2259a1 !important;
    margin: 0 !important;
}

.confirm-modal-custom .actions button:first-child:hover {
    background-color: #d8e1ec !important;
}

.confirm-modal-custom .actions button:first-child:active {
    background-color: #c2d2e5 !important;
}
