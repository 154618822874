.sort-by-selection-container {
  flex: 1;
  display: flex;
}
.sort-by-selection-container .ui.selection.dropdown {
  min-height: 33px;
  flex: 1;
  min-width: 0;
}

.sort-by-selection-container .ui.inline.dropdown .dropdown.icon {
  margin: -7px;
  right: 16px !important;
}

.sort-by-selection-container .ui.inline.dropdown > .text {
  top: 1px;
  font-weight: 100;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-right: 20px;
}

.sort-by-selection-container .ui.inline.dropdown > .menu {
  border: none;
}