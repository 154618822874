.gridview-materialName {
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0px;
  text-align: left;
  min-height: 60px;
  max-height: 60px;
  margin-top: 10px;
}
.gridview-materialDescription {
  font-family: Montserrat;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: 0px;
  text-align: left;
  min-height: 50px;
  max-height: 50px;
  overflow-y: hidden;
}

.gridView-materialTime {
  font-family: Montserrat;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0px;
  text-align: left;
}

.material-grid-view-button {
  background: #2259a1;
  mix-blend-mode: normal;
  border-radius: 4px !important;
  height: 36px;
  width: 36px;
  padding: 0 !important;
  display: flex !important;
  align-items: center;
  justify-content: center;
  position: relative;
}

.material-gridView-image-container {
  position: relative;
  width: 100%;
  height: 20%;
}

.material-gridView-image-container .checkbox {
  position: absolute;
  left: 2%;
  top: 2%;
}

.material-gridView-image-container .badge {
  position: absolute;
  left: 2%;
  bottom: 2%;
}
.material-thumbnail
{
  min-width: 100% !important;
  max-width: 100% !important;
  min-height: 150px !important;
  max-height: 250px !important;
}