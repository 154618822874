@media (max-width: 768px) {

    .adjuster-profile-page {
    height: calc(100vh - 199px) !important;
    display: flex;
    flex-direction: column;
}

    .account-header {
        min-height: 45px;
    }
    .account-header .row {
        justify-content: space-between !important;
        align-items: center !important;
    }
    .account-header-logo {
        width: max-content !important;
        padding-left: 0 !important;
    }
    .account-header-title-container {
        padding: 0 !important;
        display: block !important;
        margin-top: 0 !important;
        width: 32px !important;
        height: 32px !important;
    }
    .account-header-title-container .profile-dropdown .menu.visible {
        top: 1rem !important;
        left: -5rem !important;
    }
    .header-user-avatar {
        width: 32px !important;
        height: 32px !important;
    }

    .account-header-mobile-score {
        display: flex;
        justify-content: space-between;
        align-items: center;
        line-height: 1;
        margin-top: 10px;
    }
    .account-header-mobile-score .account-header-title {
        font-size: 24px !important;
    }

    .adjuster-profile-page .input-field-container.avatar-block-mobile {
        max-width: 100% !important;
    }

    .adjuster-profile-page .input-field-label,
    .adjuster-profile-page .checkbox-group,
    .adjuster-profile-page .checkbox-group-container,
    .adjuster-profile-page .input-field-container {
        max-width: 100% !important;
        width: 100%;
        margin-bottom: 5px;
    }
    .adjuster-profile-page .education-item {
        width: 100% !important;
    }
    .avatar-block-mobile {
        display: flex !important;
        flex-direction: row !important;
        flex-wrap: wrap !important;
        margin-top: 0 !important;
        margin-bottom: 0.5rem !important;
        width: 100% !important;
    }
    .claims-block {
        width: 100% !important;
        margin-top: 5px;
    }
    .claims-block .toggle-block{
        width: 100% !important;
    }
    .claims-block .toggle-block button{
        width: 50%;
    }

    .adjuster-profile-page .ui.form .ui.grid .equal.row .column {
        min-width: 0 !important;
    }

    .toggle-block-container-profile {
        display: flex;
        min-width: 0%;
        width: 100%;
        justify-content: flex-start !important;
    }
    .toggle-block-container-profile .toggle-block {
        width: 100% !important;
    }
    .toggle-block-container-profile button {
        width: 50% !important;
    }

    .adjuster-profile-page .experience-block {
        max-width: calc(100% - 22px) !important;
    }
}

@media (min-width: 1000px) {
.adjuster-profile-page .tabs-menu.ui.menu .item{
    padding: 0 1.05rem !important;
    margin-bottom: 0.5rem;
}
}
@media (min-width: 1200px) {
.adjuster-profile-page .tabs-menu.ui.menu .item{
    padding: 0 1.25rem !important;
}
}

.adjuster-profile-page {
    height: 100%;
    display: flex;
    flex-direction: column;
}

.avatar-block-mobile {
    /* margin-top: 0.5rem; */
    margin-bottom: 1rem;
    width: 100%;
    max-width: 660px !important;
}

.adjuster-profile-page .ui.grid .equal.row .column {
    min-width: 492px !important;
}

.adjuster-profile-page .register-adjuster-tabs.active {
    height: 300px;
}

.profile-avatar-dropdown.ui.dropdown .menu,
.profile-dropdown.ui.dropdown .menu {
    min-width: 85px !important;
}

.header-label-text {
    font-size: 16px;
    color: #57616e;
    font-weight: bold;
}

.adjuster-profile-page .ui.grid {
    margin-top: 10px !important;
    margin-left: 0 !important;
    margin-right: 0 !important;
}

.adjuster-profile-page .grid hr {
    border: 1px solid #e4e8f0;
    width: 100%;;
}

.password-change-profile-row .input-field-label {
    line-height: 1.25 !important;
}

.adjuster-email {
    position: relative;
}

.adjuster-email label::after {
    display: none !important;
}
.adjuster-email label {
    display: block !important;
    position: absolute;
    z-index: 99;
    right: 10px;
    top: 7px;
}

.claims-block .toggle-block {
    display: flex;
    width: 220px;
}

.change-input-label {
    color: #2259a1;
}

.change-input-label:hover {
    color: #2a64b1;
    cursor: pointer;
}

.change-input-label:active {
    color: #123d75;
}

.positions-string {
    max-height: 20px;
    padding-right: 15px;
    padding-left: 3px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 13px;
}

.claims-block {
  align-self: center;
  min-height: 108px;
  min-width: 65%;
  max-width: 65%;
  /*margin-left: 35px;*/
}

.availabilityNotes {
  width: 100%;
  min-height: 108px;
}

.adjuster-profile-page div.availability-for-claims {
  /*max-width: unset !important;*/
  /*justify-content: unset !important;*/
}

.claims-controls {
  display: flex;
}

.expiration-block {
  margin-left: 15px;
  position: relative;
  padding-right: 50px;
}

.expiration-block .error-label-custom {
  max-width: 140px;
}

.expiration-block .popup-wrap {
  position: absolute;
  left: 150px;
  top: 5px;
}

.expiration-checkbox {
  margin-top: 10px;
}

.adjuster-profile-page .tabs-menu.ui.menu {
    flex-wrap: wrap !important;
}

.adjuster-profile-page .add-admin-button {
    width: 120px;
}

.adjuster-profile-page .remark-label {
    width: 100%;
    min-height: 32px;
}

.toggle-block-container-profile {
    display: flex;
    min-width: 65%;
    /* justify-content: flex-end; */
}

.adjuster-profile-page .settings-header-container .tabs-menu.ui.menu .item.add-admin-button-container {
    display: flex !important;
    flex: 1 !important;
    justify-content: flex-end !important;
    padding: 0 !important;
}

.adjuster-profile-page .settings-header-container .tabs-menu.ui.menu  {
    margin-right: 0 !important;
}
.adjuster-profile-page .settings-header-container .add-admin-button-container .add-admin-button{
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    font-size: 14px !important;
    padding: 0 !important;
}

.expertise-last-item {
    position: relative;
}

.expertise-last-item .add-question {
    position: absolute !important;
    top: 75px !important;
    right: 0 !important;
}

@media (min-width:769px) and (max-width: 1260px) {

    .adjuster-profile-page .input-field-container {
        margin-right: auto;
        margin-left: auto;
        max-width: 800px;
    }

    .adjuster-profile-page .input-field-container.avatar-block-mobile {
        max-width: 800px !important;
    }
}

@media (max-width:1261px) {
  .expiration-checkbox {
    padding-left: unset;
  }

  .claims-block {
    margin: unset;
  }

  .claims-controls {
    flex-direction: column;
  }

  .claims-controls .expiration-block {
    margin-top: 10px;
    margin-left: unset;
  }
}

@media (min-width: 1261px) {
        .adjuster-profile-page .skills-grid .input-field-container {
        max-width: 800px;
    }
}

.adjuster-profile-page .avatar-block-mobile {
    max-width: 660px !important;
}

.adjuster-profile-page .input-field-label {
    width: 200px !important;
    min-width: 175px !important;
}

.adjuster-profile-page .input-field-container .form-input.dropdown .form-input-dropdown {
  width: 100% !important;
}

.adjuster-profile-page .experience-block {
    width: 100% !important;
    max-width: calc(100% - 175px);
}

.adjuster-profile-page .checkbox-group-container {
    min-width: 65%;
    /* width: auto !important; */
}

.adjuster-profile-page .skills-grid .checkbox-group-container {
    width: 100% !important;
}

.adjuster-profile-page .expertise-group .form-input-years{
    width: auto !important;
}

.adjuster-profile-page .form-input-dropdown .ui.dropdown {
    min-width: 0 !important;
}

.loss-item-label-mobile {
    min-width: 170px;
    max-width: 170px;
    font-size: 12px;
    color: #8497b0;
    margin-right: 1rem;
}

.loss-item-label {
    max-width: 100px;
    font-size: 11px;
    margin-bottom: 0.5rem;
    color: #8497b0;
}

.responsive-loss-item .expertise-group > div {
    align-self: flex-start;
}

.expertise-loss-container:first-of-type .expertise-group > div {
    align-self: flex-end !important;
}

/* @media (max-width: 900px) {

    .loss-item-label {
        font-size: 11px !important;
    }
} */
