@media (max-width: 767px) {
    .ui.striped.table tbody tr:nth-child(1n) {
        background-color: rgba(132, 151, 176, 0.1);
    }
    .ui.striped.table tbody tr:nth-child(2n) {
        background-color: rgba(0, 0, 0, 0) !important;
    }
    .ui.table.logs-table:not(.unstackable) tr {
        padding-top: 0 !important;
        padding-bottom: 0 !important;
        box-shadow: unset !important;
    }
    .ui.table.logs-table:not(.unstackable) tr > td{
        width: 100% !important;
        max-width: 100% !important;
        min-width: 0 !important;
        padding: 0.5em 0.75em !important;
    }
    .logs-table tbody {
        max-width: calc(100vw - 28px);
    }
    .filters-container .field.form-input-short {
        width: 100% !important;
    }
    .filters-container .form-input-short  > .ui.icon.input{
        width: 100% !important;
    }
    .filters-container .form-input-short  > .ui.icon.input input{
        font-size: 14px !important;
        padding-left: 30px !important;
    }

    .filters-container .ui.selection.dropdown {
        border: 1px solid transparent !important;
    }
    .filters-container .sort-by-selection-container .ui.inline.dropdown .dropdown.icon {
        right: 5px !important;
    }
    .filters-container .searchselection-container .ui.inline.dropdown .dropdown.icon {
        right: 3px !important;
    }
}

.adjusters-list-header {
    display: flex;
    max-width: 100%;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
}

.adjuster-header-actions {
    display: flex;
    align-items: center;
    max-width: 100%;
    flex-wrap: wrap;
}

.adjusters-list-header .tabs-menu {
    margin-right: 1rem !important;
}

.adjusters-list-header button {
    height: 32px;
}

.adjuster-action-button {
    height: 32px;
    width: 42px;
    padding: 0 !important;
    display: flex !important;
    align-items: center;
    justify-content: center;
}

.map-list-toogle {
    display: flex;
    width: 64px;
    height: 32px;
    overflow: hidden;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
    margin-right: 0.5rem;
}

.map-list-toogle button {
    display: flex !important;
    justify-content: center;
    width: 32px;
    height: 32px;
    padding: 0 !important;
    margin  : 0 !important;
    border-radius: 0 !important;
    -webkit-border-radius: 0 !important;
    -moz-border-radius: 0 !important;
    -ms-border-radius: 0 !important;
    -o-border-radius: 0 !important;
}

.adjusters-list-header .ui.search {
    margin-right: 10px;
    margin-left: 3px;
    height: 32px !important;
    width: 180px !important;
    align-self: center;
}

.adjusters-list-header .ui.search i {
    height: 32px !important;
    width: 32px !important;
    top: 8px !important;
    left: 8px !important;
}

.adjusters-list-header .ui.search input {
    height: 32px;
    width: 180px;
    border: 1px solid transparent;
    border-radius: 5px !important;
    -webkit-border-radius: 5px !important;
    -moz-border-radius: 5px !important;
    -ms-border-radius: 5px !important;
    -o-border-radius: 5px !important;
}

.adjusters-table td, .adjusters-table th{
    white-space: nowrap !important;
    text-overflow: ellipsis !important;
    overflow: hidden;
    font-size: 12px !important;
}

.table-avatar {
    width: 32px !important;
    max-width: 32px !important;
    overflow: visible !important;
}

.table-email {
    max-width: 250px !important;
}

.table-name {
    max-width: 85px !important;
}

.table-city {
    max-width: 85px !important;
}

.table-phone {
    max-width: 100px !important;
}

.table-action {
    overflow: visible !important;
}

.note-modal-segment {
    border: none !important;
    box-shadow: 0 0 0 0 !important;
    filter: drop-shadow(0px 4px 2px rgba(233,235,245,1));
}

.note-modal-segment .image {
    width: 36px !important;
    height: 36px !important;
}

.note-modal-segment  p{
    font-weight: 800;
    font-size: 16px;
    display: inline-block;
    margin-left: 0.5rem;
}

.message-template-input label {
    display: none !important;
}

.settings-header-container .adjuster-header-actions {
    margin-top: 0.5rem !important;
}

.settings-header-container .adjuster-header-actions button {
    margin: 0 2px !important;
}

.settings-header-container .tabs-menu {
    margin-right: 1rem !important;
}

.adjuster-admin-section {
    width: 100%;
}

.profile-updated-section {
    width: 100%;
    height: 64px;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    background: #dae2ee;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
    margin-bottom: 1rem;
}

.profile-updated-section p {
    font-size: 22px;
    font-weight: 800;
    margin: 0 1rem 0 0 !important
}

.profile-updated-section .toggle-block {
    align-self: center !important;
}

.profile-updated-section .toggle-block button{
    width: 200px;
}

.admin-toggles-section {
    min-height: 56px;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1rem;
}

.adjuster-avatar-profile-container .image {
  width: 50px !important;
  height: 50px !important;
}

.admin-toggles-block {
    min-width: max-content;
    margin-bottom: 0.5rem;
}

.admin-toggles-block .toggle-block {
    width: max-content !important;
}

.admin-toggles-block .toggle-block button {
    width: 70px;
}
.admin-toggles-block .toggle-block.adjuster-active button {
    width: 90px;
}

.internal-note-block {
    background: #DADFE9;
    max-height: 300px;
    width: 65%;
    padding: 8px;
    overflow-y: auto;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
}

.internal-note-block .note-name {
    font-size: 10px;
    font-weight: 800;
    margin: 0 !important;
    max-width: 50%;
}
.internal-note-block .note-date {
    font-size: 13px;
    color: #8497b0;
}

.admin-adjuster-profile-page .form-input {
    min-width: 60% !important;
    width: 60% !important;
}

.admin-adjuster-profile-page .form-input-short {
    min-width: 0 !important;
    width: 60% !important;
}

.admin-adjuster-profile-page .form-input-short  .field.dateinput{
    margin: 0 !important;
}

.admin-adjuster-profile-page .dateinput-container > .icon {
    left: 90px !important;
}

.admin-adjuster-profile-page .zip-form-input {
    min-width: 60% !important;
    width: 60%;
}

.admin-adjuster-profile-page .form-input-dropdown .ui.dropdown {
    width: 100% !important;
}

.form-input-short.states-input > .ui.input{
    width: 100% !important;
}

.admin-adjuster-profile-page .state-province-container .form-input-short.states-input > .ui.input {
    width: 95% !important;
}


.admin-adjuster-profile-page .education-item, .admin-adjuster-profile-page .internal-note-block {
    width: 60% !important;
}

.admin-adjuster-skills-page .form-input-short {
    min-width: 0 !important;
    width: auto !important;
}

.admin-adjuster-profile-page .input-field-label,
.admin-adjuster-skills-page .input-field-label {
    padding-top: 0 !important;
}

.files-paperwork-zone {
    padding: 8px;
    background: #dadfe9 !important;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
    margin-left: 1rem;
    margin-right: 1rem;
}

.paperwork-uloaded-file {
    position: relative;
    display: flex;
    align-items: center;
    padding-left: 8px;
    padding-right: 32px;
    height: 32px;
    margin-bottom: 8px;
    background: #e4e8f0;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
}

.paperwork-uloaded-file > span {
    position: absolute;
    right: 3px;
}

.admin-adjuster-skills-page .miles-input, .admin-adjuster-skills-page .expertise-group  {
    width: 65% !important;
}

.ui.search.dropdown.form-input-dropdown > .search {
    height: 100% !important;
}

.paperwork-file-uploader .dateinput-container {
    width: 65% !important;
}
.paperwork-file-uploader .dateinput-container > .icon {
    right: 0;
    left: 120px;
}
.paperwork-file-uploader .dateinput-container .dateinput{
    width: 100% !important;
}
.paperwork-file-uploader .dateinput-container .dateinput .input{
    width: 150px !important;
}

.adjusters-table {
    margin-bottom: 1rem !important;
}

.table-category {
    min-width: 0 !important;
    max-width: 100px !important;
    width: 30% !important;
}

.table-description {
    min-width: 0 !important;
    max-width: 100px !important;
    width: 35% !important;
}

.table-user {
    min-width: 0 !important;
    max-width: 150px !important;
    width: 20% !important;
    position: relative !important;
}

.table-user img {
    object-fit: cover;
    margin-right: 0.25em;
    display: inline-block;
    width: 2em;
    height: 2em;
    border-radius: 500rem;
}

.table-date {
    max-width: 100px !important;
    width: 15% !important;
    font-weight: normal !important;
}

.ui.table.data-table > tbody td.actions-table-row {
    position: relative;
    width: 10% !important;
    max-width: 10% !important;
}

.finance-uploader-avatar {
    position: absolute !important;
    right: 5px !important;
    top: 5px !important;
}

.filters-container {
    display: flex !important;
    width: 100%;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-top: 0.5rem;
}

.filters-container .form-input-short {
    min-width: 0 !important;
    width: auto !important;
}

.filters-container .form-input-short .dateinput-container .field.dateinput {
    margin-bottom: 0 !important;
}

.filters-container .form-input-short .dateinput-container .dateinput .ui.input {
    width: 140px !important;
}

.filter-block {
    display: flex;
    align-items: center;
    margin-bottom: 0.5rem;
}

.filter-block-mobile {
    display: flex;
    align-items: center;
    margin-bottom: 0.5rem;
    min-width: 300px;
    width: 100%;
}

.filter-label {
    margin-right: 0.5rem;
    font-size: 13px;
}

.adjuster-filter-icon {
    position: absolute !important;
    top: 8px !important;
    left: 8px !important;
}

.adjusters-filters .filter-block{
    width: 19% !important;
}

.filters-container.adjusters-filters .filter-block .form-input-short{
    width: 100% !important;
}
.filters-container.adjusters-filters .filter-block .form-input-short .ui.input{
    width: 100% !important;
}

.finance-filter-container .filter-block {
    min-width: 11%;
    max-width: 120px;
    margin-right: 0.5rem;
}

.finance-filter-container .searchselection-container .ui.search.dropdown > .text {
    max-width: 100%;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
}

.finance-filter-container .filter-block .searchselection-container {
    max-width: 100% !important;
}

.finance-filter-container .filter-block .sort-by-selection-container {
    max-width: 100% !important;
}

.finance-filter-container {
    margin-top: 1rem;
    flex-wrap: wrap;
}

.filters-container.finance-filter-container .filter-block .form-input-short .ui.input{
    width: 100% !important;
    max-width: 100% !important;
}

.filters-container.finance-filter-container .filter-block .form-input-short .ui.input {
min-width: 100% !important;
}

.finance-filter-container .filter-block.sort-by-filter-container {
    max-width: 180px;
}

.filter-actions-block {
    display: flex;
    /* flex: 1 !important; */
    align-self: flex-start;
    justify-content: flex-end;
    max-width: 100% !important;
}

.password-profile-input .error.field.form-input{
    width: 65% !important;
}

@media (max-width: 768px) {
 .password-profile-input .error.field.form-input{
    width: 100% !important;
}
}

.finance-filter-container .filter-block .sort-by-selection-container .ui.inline.dropdown > .text {
max-width: 100% !important;
}

.filters-container .searchselection-container .ui.inline.dropdown .dropdown.icon {
    right: 3px !important;
}

.filters-container .sort-by-selection-container .ui.inline.dropdown .dropdown.icon {
    right: 3px !important;
}
