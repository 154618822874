.folder-list-container {
  padding-left: 0.5rem !important;
  padding-top: 0rem !important;
  background: #dadfe9 !important;
  border-radius: 10px !important;
  -webkit-border-radius: 10px !important;
  -moz-border-radius: 10px !important;
}

.folder-list-container .list {
}

.folder-list-container .list .all {
  margin-bottom: 10px;
  padding: 8px;
  background: #dadfe9 !important;
  border-radius: 10px !important;
}
.folder-list-container .list .all:hover {
  background: #2259a1 !important;
  color: white;
  cursor: pointer;
}
.folder-list-container .folder-item {
  padding: 8px;
  background: #e6eaef !important;
  border-radius: 4px !important;
  -webkit-border-radius: 4px !important;
  -moz-border-radius: 4px !important;
}

.folder-list-container .root-folder {
  margin-left: 7px !important;
  margin-bottom: 0px !important;
  margin-top: 0px !important;
}

.folder-list-container .subfolder .item,
.folder-list-container .root-folder .folder-item {
  cursor: pointer !important;
}

.folder-list-container .root-folder.active .folder-item {
  /* background: #2a64b1 !important;
  color: white !important; */
  padding: 8px;
  border-radius: 4px !important;
  -webkit-border-radius: 4px !important;
  -moz-border-radius: 4px !important;
}

.folder-list-container .root-folder.active .folder-item.open-with-child {
  background: #2259a1 !important;
  color: white !important;
  border-radius: 4px !important;
  -webkit-border-radius: 4px !important;
  -moz-border-radius: 4px !important;
}

.folder-list-container .root-folder.active .folder-item.open-with-child.child-active:hover {
  background: #2259a1 !important;
  color: white;
}
.folder-list-container .root-folder.active .folder-item.open-with-child.child-active {
  background: #2a64b1 !important;
}

.folder-list-container .root-folder .subfolder {
  background: #e6eaef !important;
  padding: 0 0 0 0;
  border-radius: 0 0 4px 4px !important;
  -webkit-border-radius: 0 0 4px 4px !important;
  -moz-border-radius: 0 0 4px 4px !important;
  margin: 0;
}

.folder-list-container .root-folder .subfolder {
  padding: 0 !important;
}
.folder-list-container .root-folder.active .subfolder {
  background: #2a64b1 !important;
  color: white !important;
}

.folder-list-container .root-folder .subfolder .item {
  padding: 7px 10px 7px 24px !important;
}

.folder-list-container .root-folder.active .subfolder .item {
  color: white !important;
  padding: 7px 10px 7px 24px !important;
}

.folder-list-container .folder-item:hover,
.root-folder.active .subfolder .item:hover,
.folder-list-container .folder-item:hover,
.root-folder.active .subfolder .item.active {
  background: #2259a1 !important;
  color: white !important;
}

.folder-list-container .root-folder .subfolder .item:hover,
.folder-list-container .root-folder .subfolder .item:hover .training-center-icon,
.folder-list-container .folder-item:hover .training-center-icon,
.folder-list-container .folder-item:hover .open-collapse-folder-icon {
  background: #2259a1 !important;
  color: white !important;
  fill: white !important;
}

.folder-list-container .root-folder.active .subfolder .item:last-child {
  border-radius: 0 0 4px 4px !important;
  -webkit-border-radius: 0 0 4px 4px !important;
  -moz-border-radius: 0 0 4px 4px !important;
}
.folder-list-container .folder-item.active {
  background: #2259a1 !important;
  color: white !important;
}
.open-collapse-folder-icon {
  cursor: pointer;
  float: left;
  margin: 4px 5px 0 0;
  fill: #0f1e31;
}

.active .open-collapse-folder-icon,
.open-collapse-folder-icon.active,
.open-collapse-folder-icon:active,
.open-collapse-folder-icon:hover {
  fill: white;
}

.training-center-icon {
  fill: #0f1e31 !important;
}
.active .training-center-icon,
.training-center-icon.active,
.training-center-icon:active,
.training-center-icon:hover {
  fill: white !important;
}

.add-folder-button {
  height: 32px;
  align-self: center;
  font-family: 'Montserrat', serif;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 15px;
  text-transform: uppercase;
  color: #2259a1;
  cursor: pointer;
}
.add-admin-button:hover {
  color: #2259a1 !important;
}
.add-admin-button:active {
  color: #2259a1 !important;
}

.create-category-modal {
  width: calc(25% - 15px) !important;
}
.create-category-modal .field-input-container {
  width: calc(75% - 15px) !important;
}

.create-category-modal .form-input-dropdown .ui.dropdown .icon {
  right: 10px !important;
  top: 14px;
  position: absolute !important;
}
.create-category-modal .form-input-dropdown {
  height: auto;
  margin: 0 !important;
}

.create-category-modal .form-input-dropdown.long .ui.dropdown {
  width: 100% !important;
}
.create-category-modal .input-field-label {
  font-weight: bold;
}

.ui.modal.material-modal .main-content {
  background-color: #dadfe9;
  width: 100%;
  height: 100%;
  border-radius: 6px;
  padding: 10px;
  display: flex;
  flex-direction: column;
}

.ui.modal.material-modal .main-content .description {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 20px;
  padding-bottom: 20px;
}

.ui.modal.material-modal .main-content.empty-files .description {
  flex: 1;
}

.ui.modal.material-modal .main-content .description > div:first-child {
  color: #8497b0;
  font-size: 14px;
  font-weight: 100;
}
.ui.modal.material-modal .main-content .description > div.dragging {
  color: #2159a1;
}
.ui.modal.material-modal .main-content .description > div:last-child {
  color: #2159a1;
  font-size: 16px;
  font-weight: bold;
  margin-top: 5px;
}

.ui.modal.material-modal .main-content .file-list {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: auto;
  margin-bottom: 20px;
}

.ui.modal.material-modal .main-content .file-list > .file-item {
  background-color: #e4e8f0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 6px;
  border-radius: 4px;
  align-items: center;
  margin-bottom: 5px;
}
.ui.modal.material-modal .main-content .file-list > .file-item > .name {
  color: #0f1f30;
  font-size: 13px;
  font-weight: 100;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.ui.modal.material-modal .main-content .file-list > .file-item.loading > .name {
  color: #8497b0;
}
.ui.modal.material-modal .main-content .file-list > .file-item .ui.button.delete-button {
  background-color: transparent;
  padding: 0;
}
.ui.modal.material-modal .main-content .file-list > .file-item > .icon {
  min-width: 24px;
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.ui.modal.material-modal .main-content .file-list > .file-item > .icon > .ui.loader::after {
  border-color: #2159a1 #a5bad4 #a5bad4;
}

.ui.modal.material-modal .main-content .file-list > .file-item {
  background-color: #e4e8f0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 6px;
  border-radius: 4px;
  align-items: center;
  margin-bottom: 5px;
}

.ui.modal.material-modal .main-content .file-list > .file-item > .name {
  color: #0f1f30;
  font-size: 13px;
  font-weight: 100;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.ui.modal.material-modal .main-content .file-list > .file-item.loading > .name {
  color: #8497b0;
}
.ui.modal.material-modal .main-content .file-list > .file-item .ui.button.delete-button {
  background-color: transparent;
  padding: 0;
}
.ui.modal.material-modal .main-content .file-list > .file-item > .icon {
  min-width: 24px;
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.ui.modal.material-modal .main-content .file-list > .file-item > .icon > .ui.loader::after {
  border-color: #2159a1 #a5bad4 #a5bad4;
}
