.dateinput-container {
  display: inline-block;
  position: relative;
  flex: 1;
}

.dateinput-container > .icon {
  position: absolute;
  right: 4px;
  top: 4px;
}

.dateinput-container > .icon > button.ui.button {
  background-color: transparent;
  padding: 4px;
  border-radius: 4px;
  margin: 0;
}
.dateinput-container > .icon > button.ui.button:hover{
  background-color: #D8E1EC;
}
.dateinput-container > .icon > button.ui.button:active{
  background-color: #C2D2E5;
}


.dateinput-container .dateinput {
  padding: 0px 0px 0px 0px !important;
}

.dateinput-container .custom-dateinput {
  padding: 0px 0px 0px 0px !important;
  position: absolute;
  top: 0 !important;
  z-index: -1;
}

.dateinput-container .dateinput .ui.input {
  display: flex;
  flex-direction: row;
}
table.ui.celled.table td.disabled > .suicr-content-item {
  color: #aaaaaa;
}

.dateinput-container .ui.icon.buttons .button > .icon, .ui.icon.button > .icon {
  height: 18px !important;
  width: 17px !important;
}