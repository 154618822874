.ui.container.finance-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
}

.ui.container.finance-container .loading {
  margin-top: 20px;
  flex: 1;
}

.finance-list-header {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  height: 50px;
  align-items: center;
}

.finance-list-header > .right-actions > .ui.button {
  height: 32px;
}

.finance-list-header > .right-actions > .download-button {
  width: 46px;
  margin-top: 0 !important;
}

.finance-list {
  width: 100%;
  margin-top: 20px;
  flex: 1;
  display: flex;
  flex-direction: column;
}
.finance-list > table.finance-table .col-name {
  max-width: 350px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.finance-list > table.finance-table .action-col .actions-button {
  padding: 0;
}
.finance-list > .pagination-container {
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
  margin-bottom: 10px;
}
.finance-list > .no-results-container {
  height: auto !important;
  padding: 10px;
}
.finance-list > .no-results-container {
  flex: 1;
}

.finance-filter {
  display: flex;
  flex-direction: column;
}
.finance-filter > .head {
  display: flex;
  flex-direction: row;
  align-items: 'center';
  justify-content: space-between;
}
.finance-filter > .head > .title {
  font-size: 16px;
  font-weight: 700;
  color: '#535D6C';
  margin-left: 20px;
}

.finance-filter > .filter-form {
  margin-top: 20px;
}

.finance-filter > form.filter-form .ui.form.filter-form {
  display: flex;
}

.finance-filter > form.filter-form .ui.form.filter-form .fields {
  flex: 1;
}
.finance-filter > form.filter-form .ui.form.filter-form > .buttons > .ui.button {
  height: 32px;
}

.finance-filter .ui.label {
  background-color: transparent;
  margin: 0 !important;
}

.finance-filter .ui.button.reset-button {
  background-color: #DEE4F0;
  color: #2159A1;
}
.finance-filter .ui.button.reset-button:hover {
  background-color: #D8E1EC;
}
.finance-filter .ui.button.reset-button:active {
  background-color: #C2D2E5;
}

@media only screen and (min-width: 1200px) {
  .ui.modal.finance-files-uploader {
    width: 86%;
  }
}

.ui.modal.finance-files-uploader > .content {
  height: calc(80vh - 10em);
}

.ui.modal.finance-files-uploader > .content > .main-content {
  background-color: #DADFE9;
  width: 100%;
  height: 100%;
  border-radius: 6px;
  padding: 10px;
  display: flex;
  flex-direction: column;
}

.ui.modal.finance-files-uploader > .actions {
  display: flex;
  justify-content: space-between;
  padding-top: 20px;
  padding-bottom: 20px;
  border-top: none;
}
.ui.modal.finance-files-uploader > .actions > .ui.button {
  height: 32px;
}
.ui.modal.finance-files-uploader > .actions > .cancel-button {
  margin-left: 0;
  background-color: #E0E7F1;
  color: #3667A9;
}
.ui.modal.finance-files-uploader > .actions > .cancel-button:hover {
  background-color: #D8E1EC;
}
.ui.modal.finance-files-uploader > .actions > .cancel-button:active {
  background-color: #C2D2E5;
}

.ui.modal.finance-files-uploader > .content > .main-content .upload-description {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 20px;
  padding-bottom: 20px;
}

.ui.modal.finance-files-uploader > .content > .main-content.empty-files .upload-description {
  flex: 1;
}

.ui.modal.finance-files-uploader > .content > .main-content .upload-description > div:first-child {
  color: #8497B0;
  font-size: 14px;
  font-weight: 100;
}
.ui.modal.finance-files-uploader > .content > .main-content .upload-description > div.dragging {
  color: #2159A1;
}
.ui.modal.finance-files-uploader > .content > .main-content .upload-description > div:last-child {
  color: #2159A1;
  font-size: 16px;
  font-weight: bold;
  margin-top: 5px;
}

.ui.modal.finance-files-uploader > .content > .main-content .file-list {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: auto;
  margin-bottom: 20px;
}

.ui.modal.finance-files-uploader > .content > .main-content .file-list > .file-item {
  background-color: #E4E8F0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 6px;
  border-radius: 4px;
  align-items: center;
  margin-bottom: 5px;
}
.ui.modal.finance-files-uploader > .content > .main-content .file-list > .file-item > .name {
  color: #0F1F30;
  font-size: 13px;
  font-weight: 100;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.ui.modal.finance-files-uploader > .content > .main-content .file-list > .file-item.loading > .name {
  color: #8497B0;
}
.ui.modal.finance-files-uploader > .content > .main-content .file-list > .file-item .ui.button.delete-button {
  background-color: transparent;
  padding: 0;
}
.ui.modal.finance-files-uploader > .content > .main-content .file-list > .file-item > .icon {
  min-width: 24px;
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.ui.modal.finance-files-uploader > .content > .main-content .file-list > .file-item > .icon > .ui.loader::after{
  border-color: #2159A1 #A5BAD4 #A5BAD4;
}

.ui.modal.finance-files-uploader .close-button {
  position: absolute;
  right: 1em;
  top: 1em;
  cursor: pointer;
}

.ui.modal.finance-files-uploader .files-matcher-container {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.ui.modal.finance-files-uploader .files-matcher-container > .head {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}
.ui.modal.finance-files-uploader .files-matcher-container > .head > .ui.button {
  height: 32px;
}
.ui.modal.finance-files-uploader .files-matcher-container > .matcher-list {
  margin-top: 20px;
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
}
.ui.modal.finance-files-uploader .files-matcher-container > .matcher-list .col-name {
  max-width: 400px;
  /* overflow-wrap: break-word; */
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.ui.modal.finance-files-uploader .files-matcher-container > .matcher-list .col-adjuster {
  min-width: 300px;
}
.ui.modal.finance-files-uploader .files-matcher-container > .matcher-list .action-col {
  position: relative;
  width: 30px;
}
.ui.modal.finance-files-uploader .files-matcher-container > .matcher-list .action-col > .ui.loader::after{
  border-color: #2159A1 #A5BAD4 #A5BAD4;
}
.ui.modal.finance-files-uploader .files-matcher-container > .matcher-list .action-col > .ui.loading.button::after{
  border-color: #2159A1 #A5BAD4 #A5BAD4;
}
.ui.modal.finance-files-uploader .files-matcher-container > .matcher-list .action-col > .ui.button {
  padding: 0;
}
.ui.modal.finance-files-uploader .files-matcher-container > .matcher-list .personselection-container.missing .personselection-input {
  background-color: #EDC1C6;
  border-color: #E02101 !important;
}
.ui.modal.finance-files-uploader .files-matcher-container > .matcher-list .personselection-container.warning .personselection-input {
  background-color: #EFEDDE;
  border-color: #F1CE01 !important;
}
.ui.modal.finance-files-uploader .files-matcher-container > .matcher-list .personselection-container.matching .personselection-input {
  background-color: #DBEDDF;
  border-color: #3BCB0A !important;
}
.ui.modal.finance-files-uploader .files-matcher-container > .matcher-list > .no-results-container {
  flex: 1;
}
.ui.modal.finance-files-uploader .files-matcher-container > .filter-container {
  margin-top: 10px;
}
.ui.modal.finance-files-uploader .files-matcher-container > .filter-container > .head {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 15px;
}
.ui.modal.finance-files-uploader .files-matcher-container > .filter-container .filter-form .actions > .ui.button {
  height: 32px;
}
.ui.modal.finance-files-uploader .files-matcher-container > .filter-container .ui.form.filter-form {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}
.ui.modal.finance-files-uploader .files-matcher-container > .filter-container .ui.form.filter-form > .fields {
  flex: 1;
  margin: 0;
}
.ui.modal.finance-files-uploader .files-matcher-container > .filter-container .ui.form.filter-form .ui.button.reset-button {
  background-color: #DAE2EE;
  color: #2159A1;
}
.ui.modal.finance-files-uploader .files-matcher-container > .filter-container .ui.form.filter-form .ui.button.reset-button:hover {
  background-color: #D8E1EC;
}
.ui.modal.finance-files-uploader .files-matcher-container > .filter-container .ui.form.filter-form .ui.button.reset-button:active {
  background-color: #C2D2E5;
}