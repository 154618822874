.settings-page .settings-header-container .menu,
.settings-page >.grid {
    margin-left: 0 !important;
}

.settings-header-container {
    display: flex;
    flex-wrap: wrap;
    min-height: 50px;
    width: 100%;
    padding: 0;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;
}

.settings-header-container button {
    margin: 0 !important;
}

.question-container {
    min-height: 32px;
    padding: 1rem !important;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #e4e8f0;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
    margin-top: 5px;
    margin-bottom: 5px;
}

@media (min-width:925px) and (max-width:1050px) {
    .question-container {
        justify-content: flex-start;
    }
    .standby-question-input-block {
        justify-content: flex-start !important;
    }
    .question-order {
        margin-right: 1rem;
    }
    .question-type {
        margin-top: 0.8rem;
    }
}

.question-order {
    width: 36px;
    height: 36px;
    line-height: 36px;
    margin-bottom: -4px;
    text-align: center;
    font-weight: bold;
    font-size: 16px;
    color: #8497b0;
    background-color: #D0D7E3;
    border-radius: 36px;
    -webkit-border-radius: 36px;
    -moz-border-radius: 36px;
    -ms-border-radius: 36px;
    -o-border-radius: 36px;
}

.question-text {
    width: calc(100% - 370px);
}

.question-text .input{
    width: 100% !important;
    height: 100% !important;
}

.question-text label::after, #template-form label::after, #create-template-form label::after{
    display: none !important;
}

.question-type {
    display: flex;
    min-width: 160px;
    width: 160px;
}

.question-type button {
    width: 80px !important;
}

.question-icon-container {
    width: 60px;
}

.question-icon-container .grid .column {
    min-height: 32px !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
}

.question-icon-container img {
    width: 14px;
    height: 17px;
}

.trash-icon:hover {
    cursor: pointer;
}

.create-questionnarie-container {
    min-width: 650px !important;
}

.create-questionnarie-container .name-input-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-height: 32px;
    width: 100%;
    margin-bottom: 1rem;
}

.name-input-container label::after {
    display: none !important;
}

.name-input-container .field {
    width: 80% !important;
}

.add-question {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin-bottom: 5px;
}

.education-button {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.add-question button, .education-button button{
    background: none !important;
    color: #2259a1 !important;
    font-weight: bold !important;
    font-size: 14px !important;
    padding: 0 !important;
    width: 140px;
    height: 24px;
}

.education-button button:first-child {
    text-align: left;
}
.education-button button:last-child {
    text-align: right;
}


.add-question button:hover, .education-button button:hover {
    color: #2a64b1 !important;
}

.add-question button:active, .education-button button:active {
    color: #123d75 !important;
}

.add-question-form label {
    display: none !important;
}

.add-question-form {
    display: flex;
    width: 100%;
    min-height: 80px;
    justify-content: space-between;
    align-items: center;
}

.add-question-form .field {
    margin: 0 !important;
    width: 60% !important;
}

.questionnarie-list-container {
    max-height: calc(100vh - 187px) ;    
    overflow-y: auto;
    width: 228px !important;
    padding: 1rem !important;
    background: #dadfe9 !important;
    border-radius: 10px !important;
    -webkit-border-radius: 10px !important;
    -moz-border-radius: 10px !important;
    -ms-border-radius: 10px !important;
    -o-border-radius: 10px !important;
}

.questionnarie-list-column {
    width: max-content !important;
}

.questions-list-container {
    width: calc(100% - 240px) !important;
}

.questionnarie-list-container .item {
    background: #e6eaef !important;
    overflow: hidden !important;
    white-space: nowrap !important;
    text-overflow: ellipsis !important; 
}

.questionnarie-list-container .item:hover {
    background: #2a64b1 !important;
    color: white !important;
}

.questionnarie-list-container .item:active, .questionnarie-list-container .item.active:active {
    background: #123d75 !important;
    color: white !important;
}

.questionnarie-list-container .item.active {
    background: #2259a1 !important;
    color: white !important;
}

.questionnarie-list-container .ui.search input {
    border: none !important;
    outline: none !important;
    height: 32px !important;
    width: 100% !important;
    margin-bottom: 4px !important;
}

.questions-actions-buttons .ui.search input {
    border: none !important;
    outline: none !important;
    height: 32px !important;
    font-size: 16px !important;
    margin-bottom: 4px !important;
}

.manage-questions-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 32px;
    margin: 1rem 0;
    font-weight: bold;
    font-size: 22px;
}

.questions-actions-buttons {
    height: 32px;
    display: flex;
}

.questions-actions-buttons button {
    height: 32px;
    margin-right: 0 !important;
    margin-left: 10px !important;
}

.questions-actions-buttons button:first-child {
    padding: 0 !important;
    width: 42px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.manage-questions-header img {
    height: 18px;
    width: 18px;
    padding: 0 !important;
}

.questions-grid-row {
    justify-content: space-between !important;
}

.message-template-input-container {
    width: 100%;
}

.message-template-input  textarea{
    height: 100px;
    resize: none !important;
}

.prof-item-container {
    background: white;
    min-height: 32px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
    padding: 0 7px;
    margin-bottom: 4px;
}

.questions-list-container .prof-item-container {
    align-items: unset;
}

.questions-list-container .prof-item-container .error-label-custom {
    width: calc(100% + 29px) !important;
    background: #f0f2f8;
}

.twilio-numbers-container {
    max-width: 600px;
}

.prof-item-container .image:hover {
    cursor: pointer !important;
}

.prof-item-container label::after {
    display: none !important;
}

.create-prof-data-input {
    min-height: 32px !important;
    margin-left: -7px !important;
    width: 98%;
}

.create-prof-data-input form {
    margin-top: -4px !important;
}

.create-prof-data-input .input, .create-prof-data-input input {
    width: 100% !important;
}

.submit-new-prof-data {
    width: max-content !important;
    padding: 0 !important;
    height: 15px !important;
    background: none !important;
    margin: 0 !important;
}

.buy-twilio-modal .content{
    height: 400px !important;
    overflow-y: auto; 
}

.states-field {
    border-radius:5px;
    height: 32px;
    width: 310px;
    line-height: 30px;
    padding-left: 7px;
    font-size: 13px;
    color: #0f1e31;
    background: white;
    -webkit-border-radius:5px;
    -moz-border-radius:5px;
    -ms-border-radius:5px;
    -o-border-radius:5px;
}

.states-field .ui.dropdown {
    width: 295px;
}

.states-field .ui.dropdown .visible.menu {
    width: 310px !important;
    margin-left: -7px !important;
    max-height: 300px;
    overflow-y: auto;
}

.states-field .ui.dropdown .icon {
    right: 0 !important;
    top: 10px;
    position: absolute !important;
}

.numbers-buy-list .table {
    border: none !important;
}

.numbers-buy-list .table tr {
    font-size: 13px !important;
    height: 32px !important;
}

.numbers-buy-list .ui.table td {
    padding: 0 !important;
} 

.header-cell {
    font-size: 12px;
    font-weight: normal !important;
    color: #8497b0 !important;
}

.check-button {
    float: right;
    height: 22px;
    width: 22px !important;
    padding: 0 !important;
    margin-right: 10px !important;
    background: none !important;

    border-radius:3px !important;
    -webkit-border-radius:3px !important;
    -moz-border-radius:3px !important;
    -ms-border-radius:3px !important;
    -o-border-radius:3px !important;
}

.check-button.active {
    background: #2259a1 !important;
}

.check-button.active:hover {
    background: #2a64b1 !important;
}
.check-button.active:active {
    background: #123d75 !important;
}

.check-button img, .check-button.active img {
    margin: 0 auto !important;
    width: 80%;
    height: 80%;
}

.length-error {
    color: red;
}

.standby-twilio-content {
    margin-top: 1em;
}

.prof-item-input {
    width: 98%;
    margin-left: -7px;
}

.twilio-number-part {
    display: flex !important;
    align-items: center !important;
}