.folder-tags {
  display: flex;
  flex-wrap: wrap;
}

.folder-tags .tag {
  padding: 3px 8px;
  margin-right: 5px;
  margin-bottom: 5px;
  background: rgba(34, 89, 161, 0.1);
  color: #2259A1;
  font-weight: bold;
  border-radius: 15px;
  overflow-wrap: anywhere;
}
