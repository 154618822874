.material-actions-header {
  display: flex;
  max-width: 100%;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}

.material-filter-upload-actions {
  display: flex;
  align-items: center;
  max-width: 100%;
  flex-wrap: wrap;
}

.material-actions-header .tabs-menu {
  margin-right: 1rem !important;
}

.material-actions-header button {
  height: 32px;
}

.material-action-button {
  height: 32px;
  width: 42px;
  padding: 0 !important;
  display: flex !important;
  align-items: center;
  justify-content: center;
  position: relative;
}

.material-actions-header__filters {
  display: flex;
  flex-direction: row;
  margin-bottom: 5px;
}

.material-actions-header__filters .filter {
  margin-right: 1em;
  margin-top: 1em;
  flex: 1;
  display: flex;
  max-width: 322px;
}

.material-actions-header__filters .ui.search .input {
  width: 100%;
}
.material-actions-header__filters .ui.search {
  width: 100%;
}

.material-actions-header__filters .ui.search .prompt {
  border-radius: 4px !important;
  padding: 1.56em;
}
.material-actions-header__filters .ui.search i img {
  top: 5px;
}

.material-actions-header__filters .ui.selection.dropdown {
  min-height: 33px;
  flex: 1;
  min-width: 0;
  padding: 1em;
}

.material-actions-header__filters .ui.inline.dropdown .dropdown.icon {
  margin-right: -8px;
  right: 16px !important;
}

.material-actions-header__filters .ui.inline.dropdown > .text {
  top: 1px;
  font-weight: 100;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-right: 20px;
  width: 100%;
}

.material-actions-header__filters .ui.inline.dropdown > .menu {
  border: none;
}
@media only screen and (max-width: 768px) {
  .material-actions-header__filters {
    flex-direction: column;
  }

  .material-actions-header {
    justify-content: left;
  }
}
