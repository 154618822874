.material-details-modal {
  width: 640px !important;
}

.material-details-modal .modal-button-footer {
  justify-content: flex-end;
}

.material-details-modal .modal-button-footer .modal-buttons-right {
  display: flex;
  justify-content: space-between;
}

.material-details-modal .document-details {
  display: flex;
  flex-direction: column;
}

.material-details-modal .document-details .thumbnail {
  flex: 1 1;
  height: 280px;
  padding-right: 10px;
  position: relative;
}

.material-details-modal .document-details .badge {
  position: absolute;
  bottom: 4px;
  right: 4px;
}

.material-details-modal .document-details .thumbnail img {
  height: 100%;
  object-fit: fill;
}

.material-details-modal .content {
  display: flex;
  flex: 1.5 1;
  flex-direction: column;
}

.material-details-modal .label-container {
  display: flex;
  justify-content: space-between;
  padding: 5px;
}

.material-details-modal .label-container .label {
  font-size: 13px;
}

.material-details-modal .label-container .value-container {
  width: 220px;
  min-height: 32px;
  height: 100%;
  background-color: #E4E8F0;
  font-size: 13px;
  padding: 5px;
  border-radius: 5px;
}

.material-details-modal .label-container .value-container.description {
  padding-bottom: 50px;
}

.material-details-modal .embedded-video-details {
  display: flex;
  flex-flow: column;
}

.material-details-modal .embedded-video-details .video-container .video-player {
  width: 100% !important;
  margin-bottom: 10px;
}

.material-details-modal .embedded-video-details .label {
  flex: 1 1;
}

.material-details-modal .embedded-video-details .value-container {
  flex: 2 1;
}

@media (max-width: 768px) {
.material-details-modal{
  width: 95% !important;
  max-height: 96vh;
  overflow: auto;
}
}
