.ui.icon.input > i.icon {
    opacity: unset !important;
}

.ui.primary.button:hover {
    background-color: #2a64b1 !important;
}

.ui.primary.button:active {
    background-color: #123d75 !important;
}

.show-pass-icon {
    opacity: 1;
}

.show-pass-icon:hover {
    opacity: 0.7;
}

.show-pass-icon:active {
    opacity: 0.8;
}

.hide-pass-icon {
    opacity: 0.5;
}

.hide-pass-icon:hover {
    opacity: 0.7;
}

.hide-pass-icon:active {
    opacity: 0.8;
}


.ui.relaxed.grid > .column:not(.row) {
    padding-left: 0 !important;
    padding-right: 0 !important;
}

.logo-container {
    height: 300px;
    background: #f0f2f8 url('../images/compas-motives-bg.png');
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.logo-container img {
    width: 275px;
    height: 125px;
    margin-left: -30px;
}

.logo-container h3 {
    width: 780px;
    max-width: 90vw;
    text-align: center;
    font-weight: normal;
    font-size: 16px;
}

.login-form label {
    display: none !important;
}

.login-form button {
    width: 100%;
    height: 32px;
    padding: 0 !important;
    text-transform: uppercase !important;
    margin-top: 0.5em !important;
}


input[type='checkbox'] {
    outline: none !important;
}

input[type='text'] {
    border: 1px solid transparent !important;
}

input[type='text']:focus, textarea:focus {
    border: 1px solid #d8dce6 !important;
    outline: 1px solid #d8dce6 !important;
}

input[type='text']:hover, textarea:hover {
    border: 1px solid #d8dce6 !important;
}

.login-form .field {
    margin-bottom: 0.5em !important;
}

.login-page hr {
    margin: 1.5rem 0;
    border: none;
    border-top: 2px solid #cfd5e2;
}

.login-form-container p, .forgot-password-trigger {
    text-align: center;
    font-size: 16px;
    color: #2259a1;
    font-weight: bold;
    cursor: pointer;
}

.forgot-password-trigger {
    margin-bottom: 1.5rem !important;
}

.forgot-password-trigger:hover {
    color: #2a64b1;
}

.forgot-password-trigger:active {
    color: #123d75;
}

.password-strength-container {
    display: flex;
    justify-content: space-between;
    margin: 0.5rem 0;
}

.password-strength {
    font-weight: bold;
    text-transform: uppercase;
}

.password-dot-container > .circle.icon {
    font-size: 8px !important;
    margin-right: 0.10rem !important;
    color: lightgrey;
}

.weak {
    color: #a00e0e !important;
}

.fair {
    color: #FFE066 !important;
}

.good {
    color: #247BA0 !important;
}

.strong {
    color: #70C1B3 !important;
}

.avatar-input {
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100px;
    height: 100px;
    border: 1px solid lightgrey;
    border-radius: 100px;
    -webkit-border-radius: 100px;
    -moz-border-radius: 100px;
    -ms-border-radius: 100px;
    -o-border-radius: 100px;
    /* margin-left: 0.5em; */
}

.avatar-input:hover {
    cursor: pointer;
}

.avatar-input:hover.no-edit-avatar {
    cursor: unset !important;
}

.avatar-input img {
    display: block;
    margin: auto;
    width: 100%;
    height: 100%;
    object-fit: cover;
}


.avatar-edit-icon {
    position: absolute;
    top: 5px;
    left: 70px;
}

.avatar-edit-icon-button {
    background: #2259a1 !important;
    color: white !important;
    height: 35px !important;
    width: 35px !important;
    padding: 0 !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    border-radius:5px !important;

    -webkit-border-radius:5px !important;
    -moz-border-radius:5px !important;
    -ms-border-radius:5px !important;
    -o-border-radius:5px !important;
}

.avatar-edit-icon-button img {
    height: 60% !important;
    width: 60% !important;
    margin: 0 !important;
}

.custom-icon {
    top: 10px !important;
    right: 10px !important;
    padding-right: 0 !important;
    width: 21px !important;
}

.form-profile-container {
    padding-left: 0.5em !important;
    width: 600px !important;
}


.input-field-container {
    display: flex;
    justify-content: space-between;
    margin-bottom: 0.5em;
    max-width: 660px;
}

.input-field-container.temporary-location .registration-type-group {
  min-width: 65% !important;
}

#content-register-input-column .input-field-container.temporary-location .registration-type-group {
  min-width: 100% !important;
}

.input-field-label {
    font-size: 13px;
    min-width: 170px !important;
    max-width: 200px !important;
}

.input-field-label.disabled {
    color: #8497b0;
}

.form-input {
    min-width: 65%;
}

.field-input-container {
    width: 400px;
}

.remark-label {
    min-height: 32px;
    font-size: 12px;
    font-style: italic;
    text-align: left;
    width: 400px;
}

.form-input-short {
    display: flex;
    flex-direction: column;
    min-width: 65%;
}

.form-input-years, .form-input-miles {
    width: 120px;
}

    @media (max-width: 1000px) {
        .expertise-group .form-input-years {
            width: 85px !important;
            /* max-width: 85px !important; */
            /* width: 85px !important;
            max-width: 115px !important; */
            min-width: 0 !important;
        }
    }


.form-input-years i.icon, .form-input-miles i.icon {
    font-weight: bold;
    font-family: 'Montserrat';
    top: 9px !important;
    right: 5px !important;
    width: 50px !important;
    color: #8497b0;
}

.form-input-dropdown {
    min-height: 32px;
    margin: 0 !important;
}

.create-admin-modal .form-input-dropdown {
    height: auto;
    margin: 0 !important;
}

.ui.dropdown .text {
    color: black !important;
}

.field-input-container .ui.dropdown .visible.menu {
    width: 100% !important;
}

.ui.dropdown .visible.menu .item:hover, .ui.dropdown .visible.menu .item:hover span  {
    color: black !important;
    background: none !important;
}

.ui.dropdown .visible.menu .item:active, .ui.dropdown .visible.menu .item:active span   {
    color: #2259a1 !important;
}

.ui.dropdown .visible.menu .item.disabled, .ui.dropdown .visible.menu .item.disabled span  {
color: #e4e8f0 !important;
}

.ui.dropdown .visible.menu .item, .ui.dropdown .visible.menu .item span {
    color: #57616e !important;
}

.ui.dropdown .visible.menu .item.active, .ui.dropdown .visible.menu .item.active span {
    background: none !important;
    color: #2259a1 !important;
    font-weight: 400 !important;
}


.skills-grid .form-input-dropdown .ui.dropdown .icon {
    right: 10px !important;
    top: 10px;
    position: absolute !important;
}

.create-admin-modal .form-input-dropdown .ui.dropdown .icon {
    right: 10px !important;
    top: 14px;
    position: absolute !important;
}

.create-admin-modal .content {
    padding: 0 15px;
}

.form-input-dropdown .ui.dropdown{
    background: white;
    color: black !important;
    height: 32px;
    margin-bottom: 0 !important;
    line-height: 32px;
    padding-left: 5px;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
    min-width: 120px !important;
    font-size: 13px;
    width: 120px !important;
}

.register-grid .form-input-dropdown .ui.dropdown > .dropdown.icon{
    right: 5px !important;
}


.form-input-dropdown .ui.dropdown > .dropdown.icon{
    /* right: -5px !important; */
    top: 10px !important;
    position: absolute;
}

.form-input-dropdown .visible.menu {
    overflow: auto !important;
    max-height: 200px;
}

.details-grid .form-input-dropdown .ui.dropdown{
    width: 140px !important;
}

.skills-grid .field.form-input-dropdown.long{
    width: 100% !important;
    max-width: calc(100% - 120px);
}

@media (max-width:768px) {
    .skills-grid .field.form-input-dropdown.long{
    width: 100% !important;
    max-width: 100%;
}
}

.skills-grid .form-input-dropdown.long .ui.dropdown{
    width: 99% !important;
}

.skills-grid .form-input-dropdown.short .ui.dropdown{
    width: 100% !important;
    align-self: flex-end
}


.create-admin-modal .form-input-dropdown.long .ui.dropdown{
    width: 100% !important;
}

.form-input-short.zip {
    min-width: 100px;
    width: 100px;
}

input[type="date"]::-webkit-calendar-picker-indicator,
input[type="date"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-inner-spin-button{
    appearance: none;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: auto;
    height: auto;
    color: transparent;
    background: transparent;
}

.login-title-header {
    font-size: 32px !important;
    font-weight: 800 !important;
    margin-top: 50px !important;
}

@media (max-height: 760px) {
    .logo-container {
        height: 210px;
    }
    .login-title-header {
        font-size: 32px !important;
        font-weight: 800 !important;
        margin-top: 15px !important;
    }
    .forgot-password-trigger {
        margin-bottom: 0 !important;
    }
}

.form-input-short .ui.input {
    align-self: flex-start;
    min-width: 140px !important;
    width: 140px !important;
}

.form-input label, .form-input-short label, .form-input-dropdown label, .form-input-years label, .form-input-miles label, .recovery-modal-form-input label, .error-label{
    display: none !important;
}

.recovery-modal-form-input, .recovery-modal-form-input input {
    width: 300px;
}

.form-input input, .form-input-short input,  .form-input-years input  {
    height: 33px !important;
    padding-left: 8px !important;
    padding-right: 8px !important;
    font-size: 13px !important;
}

.ui.form .disabled.fields .field, .ui.form .disabled.field, .ui.form .field :disabled{
    color: #0f1e31 !important;
    opacity: 1 !important;
}

.ui.form .disabled.field input {
    background: #e4e8f0 !important;
}

.ui.form .disabled.field .ui.disabled.input {
    opacity: 1 !important;
}

.checkbox-group {
    margin: 0 !important;
    padding: 5px;
    min-width: 65% !important;
    width: 300px;
    max-height: 110px;
    height: auto;
    min-height: 32px;
    overflow: auto;
    display: flex !important;
    flex-direction: column !important;
    background: white;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
    transition: height 1s ease-in-out 1s !important;
    -moz-transition: height 1s ease-in-out 1s !important;
    -ms-transition: height 1s ease-in-out 1s !important;
    -o-transition: height 1s ease-in-out 1s !important;
    -webkit-transition: height 1s ease-in-out 1s !important;
}

.skills-grid .checkbox-group {
    width: 430px !important;
}

.checkbox-register-input:not(:last-child){
    margin: 0 0 3px 0 !important;
}

.add-button {
    text-transform: uppercase !important;
    background: transparent !important;
    color: #2259a1 !important;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    padding-right: 1rem !important;
}

.toggle-block {
    align-self: flex-start;
    overflow: hidden;
    border-radius: 5px !important;
    -webkit-border-radius: 5px !important;
    -moz-border-radius: 5px !important;
    -ms-border-radius: 5px !important;
    -o-border-radius: 5px !important;
}

.toggle-block button {
    width: 140px;
    height: 32px;
    padding: 0 !important;
    margin: 0 !important;
    text-transform: uppercase !important;
    border-radius:  0 !important;
    -webkit-border-radius:  0 !important;
    -moz-border-radius:  0 !important;
    -ms-border-radius:  0 !important;
    -o-border-radius:  0 !important;
}

.yes-btn {
    background: #2259a1 !important;
    color: white !important;
}

.yes-btn:hover {
    background: #2a64b1 !important;
}

.no-btn {
    background: white !important;
    color: #2259a1 !important;
}

.no-btn:hover {
    background: #f3f8ff !important;
    color: #2a64b1 !important;
}

.profile-avatar-dropdown .menu{
 top: 1.2em !important;
 left: 0.9em !important;
 overflow: hidden;
 border-radius: 10px !important;
 -webkit-border-radius: 10px !important;
 -moz-border-radius: 10px !important;
 -ms-border-radius: 10px !important;
 -o-border-radius: 10px !important;
}


.recovery-modal {
    width: 500px !important;
}

.recovery-modal .modal-button-footer {
    height: 66px !important;
}

.recovery-modal, .recovery-modal .content, .recovery-modal .header {
    background: #f0f2f8 !important;
}

.modal > img.ui.image {
    position: absolute !important;
    right: 1em !important;
    top: 1em !important;
}

.modal > img.ui.image:hover {
    cursor: pointer;
}

.recovery-modal .content {
    padding: 1em !important;
}

.recovery-modal .header {
    border-bottom: none !important;
    padding: 1em !important;
    padding-bottom: 0 !important;
    color: #57616e !important;
    font-size: 16px !important;
}

.error-label {
    font-size: 13px;
    color: #e10000;
    padding: 5px 0;
}
